import {
  useActions,
  VariableDropdown,
  useAutomationWorkflow,
} from 'pages/automationWorkflow/export';
import { IAssignVariableTypes } from 'pages/automationWorkflow/interface';
import { useEffect, useState } from 'react';

const AssignVariables = () => {
  const [variables, setVariables] = useState([]);
  // Extract required action functions from the custom hook
  const {
    updateWhatsappTemplateVariables,
    updateSelectedAssignVariableFallbackValue,
    updateSelectedAssignVariableCategoryAndValue,
  } = useActions();

  // Destructure required state variables from the automation workflow hook
  const {
    triggerInfo,
    selectedSaveActionsData,
    fetchWhatsappTemplateVariables,
  } = useAutomationWorkflow();

  const getWhatsAppTemplateVariable = async (
    selectedChannelId: string,
    templateId: string
  ) => {
    const res = await fetchWhatsappTemplateVariables(
      selectedChannelId,
      templateId
    );
    if (!!res) {
      const mappedVariables = res.map((val: string) => {
        return {
          fallbackValue: '',
          item: `{{${val}}}`,
          variableCategory: '',
          variableValue: '',
        };
      });
      setVariables(mappedVariables);
      updateWhatsappTemplateVariables(mappedVariables);
    }
  };

  useEffect(() => {
    if (!!selectedSaveActionsData) {
      const { whatsapp } = selectedSaveActionsData;
      if (!!whatsapp) {
        const { selectedChannelId, selectedTemplate } = whatsapp;
        getWhatsAppTemplateVariable(selectedChannelId, selectedTemplate?.id);
      }
    }
    //eslint-disable-next-line
  }, [selectedSaveActionsData?.whatsapp?.selectedTemplate]);

  return (
    <>
      {!!variables && variables.length > 0 && (
        <div className='p-4 bg-white rounded mt-6'>
          <p className='text-gray-600 text-sm font-medium'>Assign Variables</p>
          <p className='text-gray-500 text-sm'>
            Use Variable+Text to include a longer message.
          </p>
          <div className='mt-4 grid grid-flow-row gap-4'>
            {variables.map(
              (variable: IAssignVariableTypes, variableIndex: number) => (
                <div className='grid grid-cols-5 gap-4' key={variableIndex}>
                  <div className='col-span-3'>
                    <div className='flex items-center'>
                      <div className='text-indigo-400 font-medium text-sm mr-3 w-28'>
                        {`${variable?.item}`}
                      </div>
                      <div className='w-full'>
                        <VariableDropdown
                          variables={variables}
                          triggerInfo={triggerInfo}
                          updateSelectedAssignVariableCategoryAndValue={
                            updateSelectedAssignVariableCategoryAndValue
                          }
                          variableIndex={variableIndex}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='col-span-2'>
                    <input
                      type='string'
                      className='w-full py-2 px-3 shadow-sm border border-gray-300 rounded-md text-sm text-gray-700 focus:ring-primary focus:border-primary focus:outline-none'
                      placeholder='Fallback value'
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        updateSelectedAssignVariableFallbackValue(
                          e.target.value,
                          variableIndex
                        )
                      }
                      value={variables[variableIndex]?.fallbackValue ?? ''}
                    />
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default AssignVariables;
