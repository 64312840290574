import useTranslation from 'components/customHooks/useTranslation';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from 'libraryV2/ui/alert-dialog';
import { Button } from 'libraryV2/ui/button';

interface IProps {
  onDiscard: () => void;
}

const DiscardCofirmationDialog: React.FC<IProps> = ({ onDiscard }) => {
  const { t } = useTranslation();
  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button className='text-[#18181B] bg-[#FFFFFF] border-[#DFDFE2] rounded hover:bg-gray-100 hover:text-[#181818]'>
          {t('Cancel')}
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent className='bg-white'>
        <AlertDialogHeader>
          <AlertDialogTitle className='rtl:text-right'>
            {t('Discard Changes?')}
          </AlertDialogTitle>
          <AlertDialogDescription className='text-[#71717A] rtl:text-right'>
            {t(
              'Are you sure you want to discard your changes? Any unsaved modifications will be lost.'
            )}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter className='rtl:justify-start rtl:text-right'>
          <AlertDialogCancel className='bg-[#F4F4F5] hover:bg-[#E4E4E7] rtl:ml-2'>
            {t('Cancel')}
          </AlertDialogCancel>
          <AlertDialogAction
            className='ltr:ml-3 rtl:mr-3 text-white bg-[#FF0000] hover:bg-red-700'
            onClick={onDiscard}
          >
            {t('Discard')}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default DiscardCofirmationDialog;
