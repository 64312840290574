import React from 'react';
import ApplicationSideSheet from './application/index';
import BetterDocsSideSheet from './betterDocs/index';
import BrainFromAliceSideSheet from './brainFromAlice/index';
import FacebookFeedSideSheet from './facebookFeed/index';
import FacebookSideSheet from './facebookMessenger/index';
import InstagramChatSideSheet from './instagramChat/index';
import InstagramFeedSideSheet from './instagramFeed/index';
import IntntSideSheet from './intnt/index';
import LineSideSheet from './line/index';
import TelegramSideSheet from './telegram/index';
import ViberSideSheet from './viber/index';
import WebChatSideSheet from './webchat/index';
import WhatsappSideSheet from './whatsapp/index';
import WitSideSheet from './wit/index';

import { ChevronDoubleUpIcon } from '@heroicons/react/20/solid';
import { GoogleOAuthProvider } from '@react-oauth/google';
import usePartner from 'components/customHooks/usePartner';
import useTranslation from 'components/customHooks/useTranslation';
import { SelectedTeamInterface, subscriptionDetailsType } from 'index';
import { connect } from 'react-redux';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '../../../../libraryV2/ui/card';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../../../../libraryV2/ui/tooltip';
import { getQueryToken } from '../../../../utilities/utils';
import ConnectEmail from './Email';
import EmailIntegrationModal from './Email/EmailIntegrationModal';
import LivechatModal from './livechat/LivechatModal';
import { SallaIntegrationModal } from './salla';
import { ShopifyIntegrationModal } from './shopify/shopifyIntegrationModal';
import WhatsappModal from './whatsappBusiness/WhatsappModal';
import { WoocommerceIntegrationModal } from './woocommerce/woocommerceIntegrationModal';
import { ZidIntegrationModal } from './zid/zidIntegrationModal';

interface Props {
  integrationData: integrationDataProps[];
  selectedProject: SelectedTeamInterface;
  subscriptionDetails: subscriptionDetailsType;
  updateShowUpgradeModal: (payload: boolean) => void;
}

interface integrationDataProps {
  type: string;
  icon: string;
  title: string;
  description: string;
  hasAdditionalPurchase: boolean;
  isEnterpriseFeature: boolean;
}

const AvailableIntegrationsCards: React.FC<Props> = ({
  integrationData,
  selectedProject,
  subscriptionDetails,
  updateShowUpgradeModal,
}) => {
  const { t } = useTranslation();
  const [selectedPlatform, setSelectedPlatform] = React.useState('');

  const planType = selectedProject?.subscription_plan?.plan_type;
  const callbackUrl = getQueryToken('callbackUrl');
  // const primaryToken = getQueryToken('primaryToken');

  const { isPartnerRestricted, replaceWithPartnerName } = usePartner();
  const shouldReplaceMyalice = isPartnerRestricted(
    'AvailableIntegrationsCards/node/card-description'
  );

  return (
    <>
      {integrationData.map((integration: integrationDataProps, index) => (
        <Card
          key={index}
          className='w-fit cursor-pointer shadow-none rounded-md drop-shadow-sm'
          onClick={() => {
            if (
              ['free', 'premium'].includes(planType) &&
              integration?.isEnterpriseFeature
            ) {
              window.open(
                'https://meetings.hubspot.com/arnab-rahman',
                '_blank'
              );
            } else {
              if (planType === 'free' && integration?.hasAdditionalPurchase) {
                updateShowUpgradeModal(true);
              } else {
                setSelectedPlatform(integration.type);
              }
            }
          }}
        >
          <CardHeader className='flex flex-row items-center gap-2 space-x-2.5 p-5 pb-4'>
            <img
              src={integration?.icon}
              alt={'icon_box'}
              className='w-[32px] h-[32px]'
            />
            <CardTitle className='text-lg font-medium'>
              {integration?.title?.length > 25
                ? t(integration?.title).substring(0, 25) + '...'
                : t(integration?.title)}
            </CardTitle>
            {['free', 'premium'].includes(planType) &&
            integration?.isEnterpriseFeature ? (
              <div data-tip data-for={`${index}`}>
                <span className='px-1 py-0.5 items-center font-medium text-sm text-blue-800 bg-blue-100 rounded'>
                  {t('Contact Sales')}
                </span>
              </div>
            ) : planType === 'free' ? (
              integration?.hasAdditionalPurchase && (
                <div data-tip data-for={`${index}`}>
                  <span className='px-1 py-0.5 items-center font-medium text-sm text-blue-800 bg-blue-100 rounded'>
                    {t('Pro')}
                  </span>
                </div>
              )
            ) : (
              planType === 'premium' &&
              integration?.type === 'whatsapp_bsp' &&
              subscriptionDetails?.trial_availed &&
              subscriptionDetails?.trial_remaining > 0 && (
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <div
                        data-tip
                        data-for={`${index}`}
                        className='w-5 h-5 m-1.5 text-yellow-500'
                      >
                        <ChevronDoubleUpIcon />
                      </div>
                    </TooltipTrigger>
                    <TooltipContent id={`${index}`} className='w-80'>
                      You are enjoying a premium feature for{' '}
                      {subscriptionDetails?.trial_remaining} days trial. To
                      continue the usage beyond{' '}
                      {subscriptionDetails?.trial_remaining} days, you need to
                      upgrade your plan.
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              )
            )}
          </CardHeader>
          <CardContent className='p-5 pt-0'>
            <CardDescription className='text-sm text-gray-500'>
              {shouldReplaceMyalice
                ? replaceWithPartnerName(t(integration?.description))
                : t(integration?.description)}
            </CardDescription>
          </CardContent>
        </Card>
      ))}
      <SallaIntegrationModal
        selectedProject={selectedProject}
        open={selectedPlatform === 'salla'}
        handleClose={() => {
          setSelectedPlatform('');
        }}
      />

      <ZidIntegrationModal
        selectedProject={selectedProject}
        handleClose={() => {
          setSelectedPlatform('');
        }}
        open={selectedPlatform === 'zid'}
        onSubmitButtonClick={() =>
          window.open('https://web.zid.sa/market/app/2899', '_blank')
        }
      />

      <WoocommerceIntegrationModal
        handleClose={() => {
          setSelectedPlatform('');
        }}
        open={selectedPlatform === 'woocommerce'}
        selectedProject={selectedProject}
        onSubmitButtonClick={() =>
          window.open('https://wordpress.org/plugins/myaliceai/', '_blank')
        }
      />

      <ShopifyIntegrationModal
        handleClose={() => {
          setSelectedPlatform('');
        }}
        open={selectedPlatform === 'shopify'}
        selectedProject={selectedProject}
        onSubmitButtonClick={() =>
          window.open('https://apps.shopify.com/myalice-live', '_blank')
        }
      />
      {/* we are prerendering this side sheet for smooth transition. need to optimze this */}

      <LivechatModal
        open={selectedPlatform === 'livechat_messenger'}
        handleClose={() => setSelectedPlatform('')}
      />
      <EmailIntegrationModal
        open={selectedPlatform === 'gmail'}
        handleClose={() => setSelectedPlatform('')}
      />
      <GoogleOAuthProvider
        clientId={
          !!process.env.REACT_APP_GOOGLE_CLIENT_ID
            ? process.env.REACT_APP_GOOGLE_CLIENT_ID
            : ''
        }
      >
        <ConnectEmail
          open={selectedPlatform === 'custom_email'}
          handleClose={() => setSelectedPlatform('')}
        />
      </GoogleOAuthProvider>
      <WebChatSideSheet
        isOpen={selectedPlatform === 'webchat'}
        callbackUrl={callbackUrl}
        setIsSideSheetOpen={setSelectedPlatform}
        handleClose={() => setSelectedPlatform('')}
      />
      <WitSideSheet
        isOpen={selectedPlatform === 'wit'}
        callbackUrl={callbackUrl}
        setIsSideSheetOpen={setSelectedPlatform}
        handleClose={() => setSelectedPlatform('')}
      />
      <ViberSideSheet
        isOpen={selectedPlatform === 'viber_messenger'}
        callbackUrl={callbackUrl}
        setIsSideSheetOpen={setSelectedPlatform}
        handleClose={() => setSelectedPlatform('')}
      />
      <ApplicationSideSheet
        isOpen={selectedPlatform === 'app_messenger'}
        callbackUrl={callbackUrl}
        setIsSideSheetOpen={setSelectedPlatform}
        handleClose={() => setSelectedPlatform('')}
      />
      <TelegramSideSheet
        isOpen={selectedPlatform === 'telegram_messenger'}
        callbackUrl={callbackUrl}
        setIsSideSheetOpen={setSelectedPlatform}
        handleClose={() => setSelectedPlatform('')}
      />
      <LineSideSheet
        isOpen={selectedPlatform === 'line_messenger'}
        callbackUrl={callbackUrl}
        setIsSideSheetOpen={setSelectedPlatform}
        handleClose={() => setSelectedPlatform('')}
      />
      <FacebookSideSheet
        isOpen={selectedPlatform === 'facebook_messenger'}
        callbackUrl={callbackUrl}
        setIsSideSheetOpen={setSelectedPlatform}
        handleClose={() => setSelectedPlatform('')}
      />
      <FacebookFeedSideSheet
        isOpen={selectedPlatform === 'facebook_feed'}
        callbackUrl={callbackUrl}
        setIsSideSheetOpen={setSelectedPlatform}
        handleClose={() => setSelectedPlatform('')}
      />
      <InstagramChatSideSheet
        isOpen={selectedPlatform === 'instagram_messenger'}
        callbackUrl={callbackUrl}
        setIsSideSheetOpen={setSelectedPlatform}
        handleClose={() => setSelectedPlatform('')}
      />
      <InstagramFeedSideSheet
        isOpen={selectedPlatform === 'instagram_feed'}
        callbackUrl={callbackUrl}
        setIsSideSheetOpen={setSelectedPlatform}
        handleClose={() => setSelectedPlatform('')}
      />
      <BrainFromAliceSideSheet
        isOpen={selectedPlatform === 'brain_from_alice'}
        callbackUrl={callbackUrl}
        setIsSideSheetOpen={setSelectedPlatform}
        handleClose={() => setSelectedPlatform('')}
      />
      {/* <WhatsappBSPSideSheet
        isOpen={selectedPlatform === 'whatsapp_bsp'}
        callbackUrl={callbackUrl}
        setIsSideSheetOpen={setSelectedPlatform}
        handleClose={() => setSelectedPlatform('')}
      /> */}
      <WhatsappModal
        open={selectedPlatform === 'whatsapp_bsp'}
        handleClose={() => setSelectedPlatform('')}
      />
      <WhatsappSideSheet
        isOpen={selectedPlatform === 'whatsapp_messenger'}
        callbackUrl={callbackUrl}
        setIsSideSheetOpen={setSelectedPlatform}
        handleClose={() => setSelectedPlatform('')}
      />
      <IntntSideSheet
        isOpen={selectedPlatform === 'intnt'}
        callbackUrl={callbackUrl}
        setIsSideSheetOpen={setSelectedPlatform}
        handleClose={() => setSelectedPlatform('')}
      />
      <BetterDocsSideSheet
        isOpen={selectedPlatform === 'betterdocs'}
        callbackUrl={callbackUrl}
        // setIsSideSheetOpen={setSelectedPlatform}
        handleClose={() => setSelectedPlatform('')}
      />
    </>
  );
};
const mapState = (state: any) => ({
  selectedProject: state.dashboard.selectedProject,
  subscriptionDetails: state.newBilling.subscriptionDetails,
});

const mapDispatch = (dispatch: any) => ({
  updateShowUpgradeModal: (payload: boolean) =>
    dispatch.dashboard.updateShowUpgradeModal(payload),
});

const AvailableIntegrationsCardsContainer = connect(
  mapState,
  mapDispatch
)(AvailableIntegrationsCards);
export default AvailableIntegrationsCardsContainer;
