import React from 'react';
import { navigate, Button, ChevronLeftIcon, WarningModal } from '../../export';

interface WrapperProps {
  title: string;
  disableSaveButton?: boolean;
  onBackClick?: () => void;
  children: React.ReactNode;
  isConfimrationLoading?: boolean;
  onSave: () => Promise<{ status: boolean }>;
}

const Wrapper: React.FC<WrapperProps> = ({
  title,
  onSave,
  children,
  onBackClick,
  isConfimrationLoading = false,
  disableSaveButton = false,
}) => {
  const [shouldRenderWarningModal, setShouldRenderWarningModal] =
    React.useState(false);
  // This is a hacky solution to override old component CSS.
  // It directly modifies the padding of the '.layout__content' element.
  // When we move the settings to the new design, we will update it accordingly.
  // Please avoid making this a habit, as direct DOM manipulation can lead to maintenance issues.
  React.useEffect(() => {
    const layoutContent: any = document.querySelector('.layout__content');
    if (layoutContent) {
      layoutContent.style.padding = '0';
    }
  }, []);

  return (
    <>
      <div className='bg-white'>
        {/* Header */}
        <div className='flex items-center justify-between h-[52px] bg-white w-full border-b border-border-light px-4 py-[10px] sticky top-0'>
          <div className='flex items-center '>
            <Button
              size='icon'
              className='mr-3'
              variant='outline'
              onClick={() => {
                if (disableSaveButton) onBackClick && onBackClick();
                else setShouldRenderWarningModal(true);
              }}
            >
              <ChevronLeftIcon />
            </Button>
            <h4 className='text-textPrimary'>{title}</h4>
          </div>
          <Button
            onClick={async () => {
              let res = await onSave();
              if (res.status) navigate('/settings/sla-settings');
            }}
            isLoading={isConfimrationLoading}
            disabled={disableSaveButton || isConfimrationLoading}
          >
            Save Changes
          </Button>
        </div>

        {/* Body */}
        <div className='p-6'>{children}</div>
      </div>

      {shouldRenderWarningModal && (
        <WarningModal
          isOpened={shouldRenderWarningModal}
          title={'Leaving This Page?'}
          isConfirming={isConfimrationLoading}
          description={`Please save before leaving this page, otherwise you will lose your unsaved modifications.`}
          cancelTitle={`Discard Changes`}
          confirmTitle={`Save Changes`}
          onCancel={() => {
            onBackClick && onBackClick();
            setShouldRenderWarningModal(false);
          }}
          confirmationVariant='default'
          onConfirm={async () => {
            let res: { status: boolean } = await onSave();
            if (res.status) {
              onBackClick && onBackClick();
              setShouldRenderWarningModal(false);
            }
          }}
        />
      )}
    </>
  );
};

export default Wrapper;
