import React, { useState } from 'react';
import Avatar from '../../../../../library/avatar';
import PinIcon, {
  PinIconFilled,
  PinIconOnLoad,
} from '../../../assets/iconComponent/PinIcon';
import dayJs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import 'dayjs/locale/ar';
import {
  CircledChannelIcons,
  classNames,
  getDayJSFormat,
} from '../../../../../utilities/utils';
import { IPinResponse, TicketInterface } from 'pages/inbox/inboxInterface';
import { toaster } from 'evergreen-ui';
import useTranslation from 'components/customHooks/useTranslation';
import { TooltipProviderCustomised } from 'libraryV2/ui/tooltip';
import TicketPriorityLabel from 'components/utilityComponent/TicketPriorityLabel';

dayJs.extend(updateLocale);

const dateFormat = {
  future: 'in %s',
  past: '%s ago',
  s: 'Just now',
  m: 'Just now',
  mm: '%d m',
  h: '1 h',
  hh: '%d h',
  d: '1 d',
  dd: '%d d',
  M: '1 mo',
  MM: '%d mo',
  y: '1 y',
  yy: '%d y',
};
const arabicDateFormat = {
  future: 'بعد %s',
  past: 'منذ %s',
  s: '١ ث',
  m: '١ د',
  mm: '%d د',
  h: '١ س',
  hh: '%d س',
  d: '١ ي',
  dd: '%d ي',
  M: '١ ش',
  MM: '%d ش',
  y: '١ س',
  yy: '%d س',
};
dayJs.updateLocale('ar', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'a few seconds',
    m: 'a min',
    mm: '%d mins',
    h: 'an hour',
    hh: '%d hrs',
    d: 'a day',
    dd: '%d days',
    M: 'a month',
    MM: '%d months',
    y: 'a year',
    yy: '%d years',
  },
});

interface Props {
  isBulkActive: boolean;
  showAgentAvatar: boolean;
  ticketData: TicketInterface;
  currentTicket: TicketInterface;
  isTicketSelectedForBulk: (ticketId: number) => boolean;
  updatePinStatus: (
    ticketId: string,
    isPinned: boolean
  ) => Promise<IPinResponse>;
  handleOnticketClick: (ticket: TicketInterface) => void;
}

const TicketCard: React.FC<Props> = ({
  ticketData,
  isBulkActive,
  currentTicket,
  updatePinStatus,
  showAgentAvatar,
  handleOnticketClick,
  isTicketSelectedForBulk,
}) => {
  const { t, dashboardLanguage } = useTranslation();
  const isArabic = dashboardLanguage === 'arabic';
  const {
    id,
    card_text,
    is_pinned,
    is_replied,
    priority,
    assigned_agent,
    customer_full_name,
    assigned_group_name,
    assigned_group_image,
    assigned_agent_avatar,
    customer_platform_type,
    customer_platform_title,
    assigned_agent_full_name,
  } = ticketData;

  let lastMessageText = card_text;
  let isAssignedToAgent = assigned_agent !== 0;
  let platformTitle = customer_platform_title;
  let isSelected = ticketData?.id === currentTicket?.id;
  let customerName = customer_full_name || 'Anonymous User';

  const [isHovering, setHovering] = useState<boolean>(false);
  const [pinLoading, setPinLoading] = useState<boolean>(false);

  const getPinIcon = () => {
    if (pinLoading) return <PinIconOnLoad />;
    return is_pinned ? <PinIconFilled /> : <PinIcon />;
  };

  const handlePinStateUpdate = async () => {
    setPinLoading(true);
    const response: IPinResponse = await updatePinStatus(
      `${ticketData?.id}`,
      !is_pinned
    );
    response.success
      ? toaster.success(response.msg)
      : toaster.danger(response.msg);
    setPinLoading(false);
  };

  const getItemPosition = () => {
    return is_pinned || pinLoading
      ? 'ltr:right-3 rtl:left-3 top-[18px]'
      : 'ltr:right-3 rtl:left-3 top-[18px]';
  };

  const getPinClickAction = () => {
    return pinLoading
      ? () => {} //for disabling click action
      : () => {
          handlePinStateUpdate();
        };
  };

  const getTicketClassName = () => {
    if (!isBulkActive)
      return `flex h-[100px] p-3 ${
        isSelected
          ? '  border-[#2DD4BF] ltr:border-l-2 rtl:border-r-2 bg-gray-100'
          : ' border-b border-l-0 bg-white '
      } ${isHovering && 'bg-gray-50'}`;
    else
      return `flex h-[100px] py-3 px-2 border-b border-l-0 ${
        isHovering ? 'bg-gray-50' : 'bg-white'
      }`;
  };

  const getCustomerName = () => {
    let name = '';

    if (
      ['custom_email', 'office365'].includes(ticketData?.customer_platform_type)
    ) {
      name = !!ticketData?.customer_full_name
        ? ticketData?.customer_full_name
        : !!ticketData?.customer_email
        ? ticketData?.customer_email
        : 'Anonymous User';
    } else {
      name = customerName;
    }

    return {
      fullText: name,
      shortText: name.length > 20 ? name.slice(0, 20) + '...' : name,
    };
  };

  const renderCardText = () => {
    if (
      ['gmail', 'custom_email', 'office365'].includes(
        ticketData?.customer_platform_type
      )
    )
      return null;
    return (
      <div className='flex-1 w-full overflow-hidden'>
        <TooltipProviderCustomised
          side='right'
          content={lastMessageText?.length > 30 ? lastMessageText : ''}
          asChild
        >
          <span
            className={`text-sm flex leading-6 line-clamp-1 ${
              is_replied
                ? 'font-normal text-gray-500'
                : 'font-semibold text-gray-900'
            }`}
          >
            {lastMessageText}
          </span>
        </TooltipProviderCustomised>
      </div>
    );
  };

  React.useEffect(() => {}, []);

  return (
    <div className='relative cursor-pointer rtl:border-r-2 rtl:border-gray-100'>
      <div
        className={getTicketClassName()}
        onClick={() => handleOnticketClick(ticketData)}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
      >
        {/* channel and assign icon */}
        <div className='relative flex items-center'>
          <input
            type='checkbox'
            checked={isTicketSelectedForBulk(id)}
            className={classNames(
              'ltr:mr-2 rtl:ml-2 border border-gray-300 rounded text-primary without-ring',
              isBulkActive ? 'inline-block' : 'hidden'
            )}
            readOnly={true}
          />
          {CircledChannelIcons({
            width: '32',
            height: '32',
            type: customer_platform_type,
            hasHoverEffect: false,
          })}
          <div
            className={classNames(
              'absolute ltr:right-[-4px] rtl:left-[0px] border bottom-4 rounded-full border-white flex items-center',
              showAgentAvatar ? 'block' : 'hidden'
            )}
          >
            <Avatar
              data-for={`agentName`}
              size='xxs'
              src={
                isAssignedToAgent ? assigned_agent_avatar : assigned_group_image
              }
              name={
                isAssignedToAgent
                  ? assigned_agent_full_name
                  : assigned_group_name
              }
              data-tip={
                isAssignedToAgent
                  ? assigned_agent_full_name
                  : assigned_group_name
              }
              className='cursor-pointer'
            />
          </div>
        </div>

        {/* customer name */}
        <div className='flex-1 m-auto overflow-hidden leading-6 ltr:ml-4 rtl:mr-4'>
          <div className='flex justify-between w-[92%]'>
            <span
              className={`flex-grow text-sm leading-6 flex justify-between  ${
                is_replied
                  ? 'text-gray-700 font-semibold'
                  : 'text-gray-900 font-bold'
              }`}
            >
              <TooltipProviderCustomised
                content={
                  getCustomerName().fullText.length > 30
                    ? getCustomerName().fullText
                    : ''
                }
                side='right'
                asChild
              >
                <span className='flex w-full line-clamp-1'>
                  {getCustomerName().fullText}
                </span>
              </TooltipProviderCustomised>
            </span>
            <div className='justify-end flex-shrink-0'>
              <div
                className={classNames(
                  is_replied ? 'hidden' : 'inline-block',
                  'w-[6px] h-[6px] rounded-full shadow-sm bg-[#0078CF] ml-2'
                )}
              />

              {/** date and time */}
              <span
                className={`text-[12px] font-normal rtl:mr-2 ltr:ml-2 ${
                  !is_replied ? 'text-[#111827]' : 'text-gray-400'
                }`}
              >
                {!!ticketData.card_timestamp &&
                  t(
                    getDayJSFormat(
                      ticketData.card_timestamp,
                      isArabic ? arabicDateFormat : dateFormat
                    )
                  )}
              </span>
            </div>
          </div>

          {/* card text message */}
          {renderCardText()}
          {/* project name */}
          <div className='flex items-center justify-between my-1'>
            <div className={`flex items-center rounded max-w-[85%] gap-1`}>
              <TooltipProviderCustomised
                side='right'
                content={
                  !!platformTitle && platformTitle.length > 25
                    ? platformTitle
                    : ''
                }
              >
                <span
                  className={`flex w-full text-[12px] px-1 line-clamp-1 rounded ${
                    is_replied ? 'text-gray-500' : 'text-gray-900'
                  } ${isSelected ? 'bg-white' : 'bg-gray-100'}`}
                >
                  {platformTitle}
                </span>
              </TooltipProviderCustomised>
              <TicketPriorityLabel
                priority={isSelected ? currentTicket?.priority : priority}
              />
            </div>
          </div>
        </div>
      </div>
      <span
        className={classNames('absolute', getItemPosition())}
        onClick={getPinClickAction()}
      >
        {getPinIcon()}
      </span>
    </div>
  );
};

export default TicketCard;
