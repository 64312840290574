import useTranslation from 'components/customHooks/useTranslation';
import { SelectedTeamInterface } from 'index';
import BlankModal from 'library/modal/BlankModal';
import { FC } from 'react';
import WoocommerceIcon from '../../../assets/icons/marketplace/woocommerce.svg';

interface Props {
  open: boolean;
  handleClose: VoidFunction;
  selectedProject: SelectedTeamInterface;
  onSubmitButtonClick?: () => void;
}
export const WoocommerceIntegrationModal: FC<Props> = ({
  handleClose,
  open,
  selectedProject,
  onSubmitButtonClick,
}) => {
  const { t } = useTranslation();

  const handleSubmitButtonClick = () => {
    if (!!onSubmitButtonClick) {
      onSubmitButtonClick();
    }
  };
  return (
    <BlankModal
      forIntegration
      externalLink
      open={open}
      hideCancelButton
      handleClose={handleClose}
      submitButtonText={t('Continue')}
      onSubmitButtonClick={handleSubmitButtonClick}
      suggestionText={t('Need Help?')}
      documentationLink='https://docs.myalice.ai'
      linkText={t('Read Documentation')}
      headerIcon={
        <img src={WoocommerceIcon} alt='zid-icon' className='w-8 h-8' />
      }
      title={t('Integrate WooCommerce')}
      subTitle={t('Follow the steps to configure WooCommerce integration.')}
    >
      <div className='flex flex-col  rtl:text-right'>
        <h1 className='text-lg text-gray-800 font-medium leading-5'>
          {t('Connect Your Salla Store with MyAlice:')}
        </h1>
        <ul className='list-disc list-outside mx-6 text-sm font-normal leading-5'>
          <li className='my-4'>
            {t(
              "Start the Integration: Once you install MyAlice from Salla App Store, navigate to your Salla store's dashboard, go to 'Installed Applications', find MyAlice, and click 'Activate Application'."
            )}{' '}
            {t(
              'This step initiates the connection between your Salla store and MyAlice.'
            )}
          </li>
          <li className='my-4'>
            {t(
              "Enter Key Information: You will need a few pieces of information to fully enable MyAlice's capabilities on your store."
            )}{' '}
            {t("You'll find all the necessary details on the next screen.")}
          </li>
          <li className='my-4'>
            {t(
              'Enjoy Live Chat Features: With activation complete, your store will now feature a live chat option, enhancing your customer service experience.'
            )}
          </li>
        </ul>
        <p>
          {t(
            'You have full control over the live chat widget - it can be deactivated or edited at any time from your settings or directly through MyAlice integration.'
          )}
        </p>
      </div>
    </BlankModal>
  );
};
