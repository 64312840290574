import React, { useState, useRef, useEffect } from 'react';
import { LOCAL_UTILS, InfoIcon, TooltipProviderCustomised } from '../../export';
import { toNumber } from 'lodash';

type TimeInputProps = {
  value?: { days: number; hours: number; minutes: number };
  onChange: (time: { days: number; hours: number; minutes: number }) => void;
  initialDisplayValue?: string | number;
};

const TimeInput: React.FC<TimeInputProps> = ({
  value = { days: 0, hours: 0, minutes: 0 },
  onChange,
  initialDisplayValue = '',
}) => {
  const [time, setTime] = useState(value);
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef<HTMLDivElement | null>(null);
  const latestTimeRef = useRef(time);

  useEffect(() => {
    latestTimeRef.current = time; // Update ref to always hold the latest time state
  }, [time]);

  const handleClickOutside = (e: MouseEvent) => {
    if (inputRef.current && !inputRef.current.contains(e.target as Node)) {
      setIsEditing(false);
    }
    onChange && onChange(latestTimeRef.current); // Use the latest time state from the ref
  };

  useEffect(() => {
    if (isEditing) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => document.removeEventListener('mousedown', handleClickOutside);
    // eslint-disable-next-line
  }, [isEditing]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    unit: keyof typeof time
  ) => {
    const updatedTime = { ...time, [unit]: parseInt(e.target.value, 10) || 0 };
    setTime(updatedTime);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      onChange && onChange(latestTimeRef.current);
      setIsEditing(false);
    }
  };

  return (
    <div ref={inputRef} onKeyDown={handleKeyDown}>
      {isEditing ? (
        <div className='flex items-center w-full h-8 px-3 py-1 text-sm border rounded-md border-border-light focus:border-primary placeholder:subtitle-regular focus:ring-0'>
          <input
            type='number'
            className='border-0 focus:border-0 focus:ring-0 w-[33.33%] bg-transparent text-center placeholder:subtitle-regular'
            value={time.days || ''}
            onChange={(e) => handleChange(e, 'days')}
            placeholder='Days'
          />
          <span className='text-textSecondary'>|</span>
          <input
            type='number'
            className='border-0 focus:border-0 focus:ring-0 w-[33.33%] bg-transparent text-center placeholder:subtitle-regular'
            value={time.hours || ''}
            onChange={(e) => handleChange(e, 'hours')}
            max='23'
            placeholder='Hrs'
          />
          <span className='text-textSecondary'>|</span>
          <input
            type='number'
            className='border-0 focus:border-0 focus:ring-0 w-[33.33%] bg-transparent text-center placeholder:subtitle-regular'
            value={time.minutes || ''}
            onChange={(e) => handleChange(e, 'minutes')}
            max='59'
            placeholder='Mins'
          />
        </div>
      ) : (
        <div className='relative w-full'>
          <input
            type='text'
            className={`w-full h-8 px-3 py-1 text-sm border rounded-md focus:ring-0 pr-8
      ${
        initialDisplayValue === 0
          ? 'border-red-500'
          : 'border-border-light focus:border-primary'
      }
      placeholder:subtitle-regular`}
            value={(function () {
              const timeString = LOCAL_UTILS.convertMinutesToTimeString(
                toNumber(initialDisplayValue)
              );
              return timeString !== '0m' ? timeString : '';
            })()}
            placeholder='Enter value'
            onClick={() => setIsEditing(true)}
            readOnly
          />
          {initialDisplayValue === 0 && (
            <span className='absolute inset-y-0 right-0 flex items-center pr-2 text-gray-500'>
              <TooltipProviderCustomised content='This field is required'>
                <InfoIcon className='text-[#A1A1AA] h-3.5 w-3.5' />
              </TooltipProviderCustomised>
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default TimeInput;
