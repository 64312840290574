'use client';

import * as React from 'react';
import * as SwitchPrimitives from '@radix-ui/react-switch';

import { cn } from 'libraryV2/utils';

// Define sizes
const sizes = {
  md: { root: 'h-5 w-9', thumb: 'h-4 w-4', thumbTranslate: 'translate-x-5' },
  lg: { root: 'h-6 w-11', thumb: 'h-5 w-5', thumbTranslate: 'translate-x-6' },
};

// Set default size
const defaultSize = 'md';

const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root> & {
    size?: keyof typeof sizes;
  }
>(({ checked, className, size = defaultSize, ...props }, ref) => {
  const { root, thumb } = sizes[size] || sizes[defaultSize];

  return (
    <SwitchPrimitives.Root
      className={cn(
        `peer inline-flex shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent shadow-sm transition-colors disabled:cursor-not-allowed disabled:opacity-50 ${root} ${
          checked ? 'bg-primary' : 'bg-[#F4F4F5]'
        }`,
        className
      )}
      {...props}
      ref={ref}
    >
      <SwitchPrimitives.Thumb
        className={cn(
          `pointer-events-none block rounded-full bg-white shadow-lg ring-0 transition-transform ${
            checked ? 'translate-x-full' : 'translate-x-0'
          } ${thumb}`
        )}
      />
    </SwitchPrimitives.Root>
  );
});
Switch.displayName = SwitchPrimitives.Root.displayName;

export { Switch };
