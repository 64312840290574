const devUrl = 'http://localhost:8000';
const productionUrl = 'https://prod-api.myalice.ai';
const stagingUrl = 'https://pre-prod-api.myalice.ai';
const automationWorkflowProductionUrl =
  'https://workflow-api.myalice.ai/workflow';
const automationWorkflowStagingUrl = 'https://workflow.myalice.ai/workflow';

const partnerURL = {
  MERCK: 'https://merck-api.myalice.ai',
};

export const status = process.env.REACT_APP_ENV || 'staging'; //default set to staging

export const getAPIURL = () => {
  const appClientType = process.env.REACT_APP_CLIENT_TYPE;
  const status = process.env.REACT_APP_ENV;

  switch (appClientType) {
    case 'merck':
      return partnerURL.MERCK;
    default:
      return status === 'production'
        ? productionUrl
        : status === 'staging'
        ? stagingUrl
        : devUrl;
  }
};

export const apiURL = getAPIURL();

export const env = status;
export const versionNumber = 'v1.26.3'; //for force logout change version number

export const environment = {
  STAGE: 'staging',
  PRODUCTION: 'production',
  DEV: 'dev',
};

export const LiveTestingTeam = {
  'Project Inquisition': 9,
  'Alice Labs': 343,
  'MyAlice WooCommerce': 1379,
  'MyAlice Shopify': 1451,
  "imu 6's Team": 2694,
  'MyAlice Testing Team': 5179,
};

const automationWorkflowUrl =
  status === 'production'
    ? automationWorkflowProductionUrl
    : automationWorkflowStagingUrl;

export const defaultTeamListforEdgeFeature = [9];

// please note: key inside config defines model and each function inside model defines
// its url as function. provide possible params as needed to construct url in the fucntion

const config = {
  auth: {
    login: () => `${apiURL}/api/accounts/login`,
    register: () => `${apiURL}/api/accounts/register`,
    registerV2: () => `${apiURL}/edge/accounts/register`,
    appSumoRegister: () => `${apiURL}/api/appsumo/register`,
    appSumoValidateTokenURL: () =>
      `${apiURL}/api/appsumo/validate-appsumo-installation-request`,
    resendRegisterVerifyLink: () =>
      `${apiURL}/api/accounts/resend-verification-mail`,
    registerVerify: (apiVersion, parameter) =>
      `${apiURL}/${apiVersion}/accounts/verify-registration?code=${parameter}`,
    resetRequest: () => `${apiURL}/api/accounts/request-password-reset`,
    resetVerify: () => `${apiURL}/api/accounts/verify-password-reset`,
    refresh: () => `${apiURL}/api/accounts/refresh`,
    info: () => `${apiURL}/api/accounts/info`,
    updateStatus: () => `${apiURL}/api/accounts/update-status`,
    acceptInvite: () => `${apiURL}/api/bots/projects/invitation-accept`,
    acceptTeamInvite: () =>
      `${apiURL}/api/bots/projects/accept-team-invitation`,
    logout: () => `${apiURL}/api/accounts/logout`,
    validateInvitaionToken: () =>
      `${apiURL}/api/bots/projects/validate-team-invitation`,
    verifyOtp: () => `${apiURL}/api/accounts/verify-otp`,
    resendOtp: () => `${apiURL}/api/accounts/resend-otp`,
  },
  onboarding: {
    // to be removed ->
    countryList: () => `${apiURL}/api/accounts/country-list`,
    updateInfo: () => `${apiURL}/api/onboarding/set-basic-info`,
    businessInfo: () => `${apiURL}/api/onboarding/set-business-info`,
    createChannel: () => `${apiURL}/api/onboarding/create-channels`,
    onboardingState: () => `${apiURL}/api/onboarding/update-onboarding-state`,
    getOnboardingStateURL: () =>
      `${apiURL}/edge/onboarding/get-onboarding-state`,
    inviteMember: () => `${apiURL}/api/onboarding/invite-team-member`,
    //to be removed <-

    createChannelIntegrations: (channelType, teamId) =>
      `${apiURL}/edge/channels/projects/${teamId}/${channelType}/create`,
    facebookPages: (userId, token, channelType) =>
      `${apiURL}/edge/accounts/facebook/${userId}/pages?token=${token}&platform_type=${channelType}`,
  },
  reporting: {
    analytics: (projectId, endpoint) =>
      `${apiURL}/api/analytics/projects/${projectId}/${endpoint}`,
    heatmap: (projectId) =>
      `${apiURL}/api/analytics/projects/${projectId}/conversation_heatmap`,
    automationTicketTable: (projectId) =>
      `${apiURL}/api/analytics/projects/${projectId}/get_ticket_list`,
    exportTicket: (projectId) =>
      `${apiURL}/api/analytics/projects/${projectId}/export_ticket_list`,
    exportAgentDetails: (projectId) =>
      `${apiURL}/api/analytics/projects/${projectId}/export_agent_stats`,
    exportIncomingVsAutomationChart: (projectId) =>
      `${apiURL}/api/analytics/projects/${projectId}/export-textual-message-vs-automation`,
    exportTagCloud: (projectId) =>
      `${apiURL}/api/analytics/projects/${projectId}/export_tag_cloud`,
    conversationLog: (projectId) =>
      `${apiURL}/api/analytics/projects/${projectId}/get_conversation_logs`,
    intent: (projectId) => `${apiURL}/api/bots/projects/${projectId}/intents`,
    csat: (teamId, url) => `${apiURL}/api/analytics/projects/${teamId}/${url}`,
    csatResponseList: (teamId) =>
      `${apiURL}/api/analytics/projects/${teamId}/csat-bucket-list`,
    csatResponseDist: (teamId) =>
      `${apiURL}/api/analytics/projects/${teamId}/csat-response-distribution`,
    exportCSAT: (projectId) =>
      `${apiURL}/api/analytics/projects/${projectId}/export_csat_list`,
    conversationMetrics: (projectId) =>
      `${apiURL}/api/analytics/projects/${projectId}/get-whatsapp-conversation-metrics`,
    templateMetrics: (projectId) =>
      `${apiURL}/api/analytics/projects/${projectId}/get-whatsapp-template-metrics`,
    templateAnalyticsLogs: (projectId) =>
      `${apiURL}/api/analytics/projects/${projectId}/get-whatsapp-template-activity-logs`,
  },
  automation: {
    api: (teamId) => `${apiURL}/api/bots/projects/${teamId}/custom_api`,
    apiAction: (teamId, apiId) =>
      `${apiURL}/api/bots/projects/${teamId}/custom_api/${apiId}`,
    getAllTriggerData: (ecommerceTag) =>
      `${apiURL}/stable/ecommerce/get-all-trigger-data?ecommerce_tag=${ecommerceTag}`,
    getSelectedTriggerDetails: (ecommerceTag, triggerCode) =>
      `${apiURL}/stable/ecommerce/get-specific-trigger-data?ecommerce_tag=${ecommerceTag}&trigger_code=${triggerCode}`,
  },
  customers: {
    customers: () => `${apiURL}/api/bots/customers`,
    filterCustomer: () => `${apiURL}/api/bots/customers/filters`,
  },
  blocks: {
    blocks: (sequenceId) => `${apiURL}/api/bots/sequences/${sequenceId}/blocks`,
    blocksAction: (sequenceId, blockId) =>
      `${apiURL}/api/bots/sequences/${sequenceId}/blocks/${blockId}`,
    subscription: (channelId) =>
      `${apiURL}/api/bots/platforms/${channelId}/subscriptions`,
    reOrderBlock: (sequenceId) =>
      `${apiURL}/api/bots/sequences/${sequenceId}/blocks/change-serial`,
    dataLabBlock: (teamId, labId) =>
      `${apiURL}/api/bots/projects/${teamId}/data_lab/${labId}`,
    productDiscoveryBlock: (teamId) =>
      `${apiURL}/stable/ecommerce/projects/${teamId}/get-product-discovery-block-parameters`,
  },
  builder: {
    sequence: (channelId) =>
      `${apiURL}/api/bots/platforms/${channelId}/sequences`,
    sequenceAction: (channelId, sequenceId) =>
      `${apiURL}/api/bots/platforms/${channelId}/sequences/${sequenceId}`,
    entities: (channelId) =>
      `${apiURL}/api/bots/platforms/${channelId}/entities`,
    subscription: (channelId) =>
      `${apiURL}/api/bots/platforms/${channelId}/subscriptions`,
    attribute: (channelId) =>
      `${apiURL}/api/bots/platforms/${channelId}/attributes`,
  },
  crmEcommerce: {
    cart: (customerId) => `${apiURL}/api/ecommerce/customers/${customerId}`,
    products: (teamId) =>
      `${apiURL}/edge/ecommerce/projects/${teamId}/products`,
    updateCart: (customerId) =>
      `${apiURL}/edge/ecommerce/customers/${customerId}/update-cart`,
    variant: (teamId, productId) =>
      `${apiURL}/edge/ecommerce/projects/${teamId}/products/${productId}/variations`,
    createOrder: (projectId) =>
      `${apiURL}/stable/ecommerce/projects/${projectId}/create-order`,
    updateOrder: (teamId, orderId) =>
      `${apiURL}/edge/ecommerce/projects/${teamId}/update-order/${orderId}`,
    getOrder: (teamId, orderId) =>
      `${apiURL}/stable/ecommerce/projects/${teamId}/get-order/${orderId}`,
    searchOrder: (teamId) =>
      `${apiURL}/edge/ecommerce/projects/${teamId}/search-orders`,
    searchedOrderProducts: (productURL) =>
      `${apiURL}/stable/ecommerce/${productURL}`,
    paymentMethod: (teamId) =>
      `${apiURL}/edge/ecommerce/projects/${teamId}/payment-methods`,
    shippingMethod: (teamId) =>
      `${apiURL}/edge/ecommerce/projects/${teamId}/shipping-methods`,
    viewCartParameter: (teamId) =>
      `${apiURL}/stable/ecommerce/projects/${teamId}/get-view-cart-block-parameters`,
    couponDiscoveryBlockParameter: (teamId) =>
      `${apiURL}/stable/ecommerce/projects/${teamId}/get-coupon-block-parameters`,
    calculateDraftOrder: (teamId) =>
      `${apiURL}/edge/ecommerce/projects/${teamId}/calculate-draft-order`,
    calculateUpdateOrder: (teamId, orderId) =>
      `${apiURL}/edge/ecommerce/projects/${teamId}/calculate-update-order/${orderId}`,
    getWocommerceCoupons: (teamId) =>
      `${apiURL}/edge/ecommerce/projects/${teamId}/coupons`,
    shopifyUpdateOrder: (teamId, orderId) =>
      `${apiURL}/stable/ecommerce/projects/${teamId}/update-order/${orderId}`,
    recommendProducts: (customerId) =>
      `${apiURL}/stable/ecommerce/customers/${customerId}/recommend-products`,
  },
  crm: {
    tickets: (teamId) => `${apiURL}/edge/crm/projects/${teamId}/tickets`,
    ticketAction: (teamId, ticketId) =>
      `${apiURL}/api/crm/projects/${teamId}/tickets/${ticketId}`,
    agent: (teamId) => `${apiURL}/api/crm/projects/${teamId}/agents`,
    ticketString: (teamId, paramsString) =>
      `${apiURL}/api/crm/projects/${teamId}/tickets?${paramsString}`,
    gallery: (teamId) => `${apiURL}/api/crm/projects/${teamId}/images`,
    conversationResolve: (ticketId) =>
      `${apiURL}/api/crm/tickets/${ticketId}/action-resolve`,
    conversationLock: (ticketId) =>
      `${apiURL}/api/crm/tickets/${ticketId}/action-lock`,
    customerMeta: (ticketId) => `${apiURL}/api/bots/customers/${ticketId}/meta`,
    botStatus: (ticketId) => `${apiURL}/api/crm/tickets/${ticketId}/action-bot`,
    teamTagListCreate: (teamId) =>
      `${apiURL}/api/crm/projects/${teamId}/ticket-tags`,
    teamTagUpdateDelete: (teamId, tagId) =>
      `${apiURL}/edge/crm/projects/${teamId}/ticket-tags/${tagId}`,
    ticketTagAction: (ticketId) => `${apiURL}/api/crm/tickets/${ticketId}/tag`,
    customerTags: (teamId) =>
      `${apiURL}/api/bots/projects/${teamId}/customer-tags`,
    attributes: (customerId) =>
      `${apiURL}/api/crm/customers/${customerId}/attributes`,
    messengerChat: (ticketId) =>
      `${apiURL}/api/crm/tickets/${ticketId}/messenger-chat`,
    feedThread: (ticketId) =>
      `${apiURL}/api/crm/tickets/${ticketId}/feed-thread`,
    editNotification: (teamId, accessId) =>
      `${apiURL}/api/bots/projects/${teamId}/access/${accessId}`,
    editUseNewInbox: (teamId, accessId) =>
      `${apiURL}/api/bots/projects/${teamId}/access/${accessId}`,
    adminAssign: (ticketId) =>
      `${apiURL}/api/crm/tickets/${ticketId}/action-assign`,
    groups: (teamId) => `${apiURL}/api/bots/projects/${teamId}/groups`,
    groupDetails: (teamId, groupId) =>
      `${apiURL}/api/bots/projects/${teamId}/groups/${groupId}`,
    groupsAction: (teamId, groupId) =>
      `${apiURL}/api/bots/projects/${teamId}/groups/${groupId}`,
    cannedResponse: (teamId) =>
      `${apiURL}/api/crm/projects/${teamId}/canned-responses`,
    cannedResponseAction: (teamId, cannedId) =>
      `${apiURL}/api/crm/projects/${teamId}/canned-responses/${cannedId}`,
    orderList: (customerId) =>
      `${apiURL}/edge/ecommerce/customers/${customerId}/order-list`,
    productInteraction: (customerId) =>
      `${apiURL}/edge/ecommerce/customers/${customerId}/product-interactions`,
    orderSummary: (customerId) =>
      `${apiURL}/edge/ecommerce/customers/${customerId}/order-summary`,
    cancelOrder: (teamId, orderId) =>
      `${apiURL}/stable/ecommerce/projects/${teamId}/cancel-order/${orderId}`,
    refundOrder: (teamId, orderId) =>
      `${apiURL}/stable/ecommerce/projects/${teamId}/refund-order/${orderId}`,
    image: (teamId) => `${apiURL}/api/crm/projects/${teamId}/images`,
    reopenTicket: (ticketId) =>
      `${apiURL}/api/crm/tickets/${ticketId}/action-reopen`,
    createWhatsAppTicket: (teamId) =>
      `${apiURL}/edge/crm/projects/${teamId}/create-whatsapp-ticket`,
    getWhatsAppMesssageTemplates: (platformId) =>
      `${apiURL}/stable/channels/${platformId}/get-whatsapp-message-templates`,
  },
  dashboard: {
    projects: () => `${apiURL}/api/dashboard/teams`,
    teamDetails: (teamId) => `${apiURL}/api/dashboard/team/${teamId}/details`,
    intent: (platformId) =>
      `${apiURL}/api/bots/platforms/${platformId}/intents`,
    permission: (teamId) => `${apiURL}/api/bots/projects/${teamId}/permissions`,
    channelList: (teamId) =>
      `${apiURL}/api/bots/projects/${teamId}/platforms/list`,
    projectProgress: (teamId) =>
      `${apiURL}/edge/accounts/progress-data/${teamId}`,
    dashboardMarketingData: () => `https://myalice.ai/api/marketing-block/`,
    billingCard: (teamId) =>
      `${apiURL}/api/dashboard/team/${teamId}/billing-card`,
    salesCard: (teamId) => `${apiURL}/api/dashboard/team/${teamId}/sales-card`,
    customerCard: (teamId) =>
      `${apiURL}/api/dashboard/team/${teamId}/customer-card`,
    ticketCard: (teamId) =>
      `${apiURL}/api/dashboard/team/${teamId}/ticket-card`,
    ratingCard: (teamId) =>
      `${apiURL}/api/dashboard/team/${teamId}/rating-card`,
    teamCreate: () => `${apiURL}/api/dashboard/create-team`,
    ticketAssignCount: (projectId) =>
      `${apiURL}/edge/dashboard/projects/${projectId}/ticket-assignment-count`,
    ticketAssignReport: (projectId) =>
      `${apiURL}/edge/dashboard/projects/${projectId}/platform-ticket-assignment-reports`,
    assignedAgents: (projectId) =>
      `${apiURL}/edge/dashboard/projects/${projectId}/assigned-agents`,
    ticketCountOfAgent: (projectId) =>
      `${apiURL}/edge/dashboard/projects/${projectId}/ticket-count-of-agent`,
  },
  datalab: {
    datalab: (teamId) => `${apiURL}/api/bots/projects/${teamId}/data_lab`,
    datalabAction: (teamId, datalabId) =>
      `${apiURL}/api/bots/projects/${teamId}/data_lab/${datalabId}`,
    field: (datalabId) => `${apiURL}/api/bots/labs/${datalabId}/fields`,
    fieldAction: (datalabId, fieldId) =>
      `${apiURL}/api/bots/labs/${datalabId}/fields/${fieldId}`,
    fieldSerial: (datalabId) =>
      `${apiURL}/edge/form/${datalabId}/change-serial`,
    entries: (datalabId) => `${apiURL}/api/bots/labs/${datalabId}/entries`,
    entriesAction: (datalabId, entriesId) =>
      `${apiURL}/api/bots/labs/${datalabId}/entries/${entriesId}`,
    export: (datalabId) => `${apiURL}/api/bots/labs/${datalabId}/export`,
    import: (datalabId) => `${apiURL}/api/bots/labs/${datalabId}/import`,
    publicSubmission: (datalabId) =>
      `${apiURL}/api/bots/labs/${datalabId}/submission/public`,
    // datalab-v2-api
    getDatalabInfo: (teamId, labId) =>
      `${apiURL}/edge/form/projects/${teamId}/id/${labId}`,
    datalabListData: (teamId, datalabId) =>
      `${apiURL}/edge/form/${datalabId}/entries`,
    datalabDeleteList: (labId) => `${apiURL}/edge/form/${labId}/bulk-delete`,
    datalabCreateEntry: (labId) => `${apiURL}/edge/form/${labId}/entries`,
    updateCreateEntry: (labId, fieldId) =>
      `${apiURL}/edge/form/${labId}/entries/${fieldId}`,
    datalabExport: (datalabId) => `${apiURL}/edge/form/${datalabId}/export`,
    datalabImport: (datalabId) => `${apiURL}/edge/form/${datalabId}/import`,

    // Data lab filter api
    datalabCreateFilter: (labId) => `${apiURL}/edge/form/${labId}/filters`,
    datalabGetFilterData: (labId) => `${apiURL}/edge/form/${labId}/filters`,
    datalabUpdateFilter: (labId, filterId) =>
      `${apiURL}/edge/form/${labId}/filter/${filterId}`,
    datalabDeleteFilter: (labId, filterId) =>
      `${apiURL}/edge/form/${labId}/filter/${filterId}`,
  },
  datalabFormBuilder: {
    datalabAction: (teamId, datalabId) =>
      `${apiURL}/edge/form/projects/${teamId}/id/${datalabId}`,
    field: (datalabId) => `${apiURL}/edge/form/${datalabId}/fields`,
    fieldAction: (datalabId, fieldId) =>
      `${apiURL}/edge/form/${datalabId}/fields/${fieldId}`,
    fieldSerial: (datalabId) =>
      `${apiURL}/edge/form/${datalabId}/change-serial`,
  },
  // these endpoints set are used in inbox datalab.
  inboxDatalab: {
    datalabList: (teamId, apiVersion = 'api') =>
      `${apiURL}/api/bots/projects/${teamId}/data_lab`,
    datalabFields: (teamId, datalabId, apiVersion = 'api') =>
      `${apiURL}/edge/form/projects/${teamId}/id/${datalabId}`,
    field: (datalabId) => `${apiURL}/api/bots/labs/${datalabId}/fields`,
    fieldAction: (datalabId, fieldId) =>
      `${apiURL}/edge/form/${datalabId}/fields/${fieldId}`,
    datalabEntries: (datalabId, apiVersion = 'api') =>
      `${apiURL}/edge/form/${datalabId}/entries`,
    customerDatalabEntries: (customerId, limit, offset, apiVersion = 'api') =>
      `${apiURL}/edge/form/entries/customer/${customerId}?limit=${limit}&offset=${offset}`,
    entriesAction: (datalabId, entriesId, apiVersion = 'api') =>
      `${apiURL}/edge/form/${datalabId}/entries/${entriesId}`,
    export: (datalabId) => `${apiURL}/edge/form/${datalabId}/export`,
    import: (datalabId) => `${apiURL}/edge/form/${datalabId}/import`,
    publicSubmission: (datalabId) =>
      `${apiURL}/edge/form/${datalabId}/submission/public`,
  },
  rules: {
    rules: (sequenceId) => `${apiURL}/api/bots/sequences/${sequenceId}/rules`,
    rulesAction: (sequenceId, ruleId) =>
      `${apiURL}/api/bots/sequences/${sequenceId}/rules/${ruleId}`,
    permission: (source) => `${apiURL}/api/bots/rule-info/${source}`,
    qr: (ruleId) => `${apiURL}/api/bots/rules/${ruleId}/qrcode`,
  },
  billing: {
    stripePublicKey: () => `${apiURL}/api/billing/get-stripe-public-key`,
    plans: () => `${apiURL}/api/billing/plans`,
    history: (teamId) =>
      `${apiURL}/api/billing/projects/${teamId}/billing-history`,
    creatStripePaymentMethod: () =>
      `${apiURL}/api/billing/create-stripe-payment-method`,
    stripeSubscription: () =>
      `${apiURL}/api/billing/handle-stripe-subscription`,
    handleShopifySubscription: () =>
      `${apiURL}/stable/billing/handle-shopify-subscription`,
    confirmShopifySubscription: () =>
      `${apiURL}/stable/billing/confirm-shopify-subscription`,
    updateStripePaymentMethod: () =>
      `${apiURL}/api/billing/update-stripe-payment-method`,
    refund: () => `${apiURL}/api/billing/refund-request`,
    startTrial: () => `${apiURL}/api/billing/start-trial`,
    billingCoupon: () => `${apiURL}/api/billing/check-stripe-coupon`,
    // new billing
    availablePlans: () => `${apiURL}/api/billing/available-plans`,
    subscriptionDetails: (teamId) =>
      `${apiURL}/api/billing/projects/${teamId}/subscription-details`,
    initiateSubscriptionPlanUpdate: (teamId) =>
      `${apiURL}/api/billing/projects/${teamId}/initiate-subscription-plan-update`,
    billingInformationUpdate: () =>
      `${apiURL}/api/billing/update-billing-information`,
    paymentDetails: () => `${apiURL}/api/billing/payment-details`,
    feedback: () => `${apiURL}/api/billing/feedback`,
    estimatedCost: (teamId) =>
      `${apiURL}/api/billing/projects/${teamId}/billing-cost-details`,
    cancelSubscription: (teamId) =>
      `${apiURL}/stable/billing/projects/${teamId}/cancel-subscription`,
    reactivateSubscription: (teamId) =>
      `${apiURL}/api/billing/projects/${teamId}/reactivate-subscription`,
  },
  channel: {
    initialize: (teamId) =>
      `${apiURL}/api/bots/projects/${teamId}/platforms/initialize`,
    facebookPages: (userId, token, channelType) =>
      `${apiURL}/api/accounts/facebook/${userId}/pages?token=${token}&platform_type=${channelType}`,

    facebookPagesWithoutType: (userId, token) =>
      `${apiURL}/api/accounts/facebook/${userId}/pages?token=${token}`,

    instagramPage: (userId, token, channelType, projectId = '') =>
      `${apiURL}/api/accounts/instagram/${userId}/pages?token=${token}&platform_type=${channelType}&project_id=${projectId}`,
    connect: (channelId) => `${apiURL}/api/bots/platforms/${channelId}/connect`,
    disconnect: (channelId) =>
      `${apiURL}/api/bots/platforms/${channelId}/disconnect`,
    archive: (channelId) => `${apiURL}/api/bots/platforms/${channelId}/archive`,
    secondaryReciever: (channelId) =>
      `${apiURL}/api/bots/platforms/${channelId}/secondary-receiver`,
    update: (channelId) => ` ${apiURL}/edge/bots/platforms/${channelId}/update`,
    connectChannel: (teamId) =>
      `${apiURL}/api/bots/projects/${teamId}/platforms/connect`,
    disconnectChannel: (teamId) =>
      `${apiURL}/api/bots/projects/${teamId}/platforms/disconnect`, // confusing as we already have a disconnect URL
    persistentMenu: (channelId) =>
      `${apiURL}/api/bots/platforms/${channelId}/persistent-menu`,
    cloneChannel: (channelId) =>
      `${apiURL}/api/bots/platforms/${channelId}/clone`,
    disallowedBlocks: (channelId) =>
      `${apiURL}/api/bots/platforms/${channelId}/disallowed-blocks`,
  },
  marketPlace: {
    marketPlaceData: (teamId) =>
      `${apiURL}/edge/ecommerce/projects/${teamId}/get-ecommerce-data`,
    initializeWooCommerce: (teamId) =>
      `${apiURL}/edge/ecommerce/projects/${teamId}/initialize-woocommerce-integration`,
    initializeShopify: (teamId) =>
      `${apiURL}/edge/ecommerce/projects/${teamId}/initialize-shopify-integration`,
    disconnectEcommerce: (teamId) =>
      `${apiURL}/edge/ecommerce/projects/${teamId}/remove-ecommerce-integration`,
    disconnectPlugin: (teamId) =>
      `${apiURL}/edge/ecommerce/projects/${teamId}/remove-plugin-connection`,
    customerList: (teamId) =>
      `${apiURL}/edge/ecommerce/projects/${teamId}/customer-list`,
    webchatEcommerceConnect: (teamId) =>
      `${apiURL}/edge/ecommerce/projects/${teamId}/connect-webchat-platform-to-ecommerce-integration`,
    webchatChannels: (teamId) =>
      `${apiURL}/edge/ecommerce/projects/${teamId}/webchat-platforms`,
    linkCustomer: (teamId) =>
      `${apiURL}/edge/ecommerce/projects/${teamId}/link-customer`,
    unlinkCustomer: (teamId) =>
      `${apiURL}/edge/ecommerce/projects/${teamId}/unlink-customer`,
    woocommerceConnectedCallback: () =>
      `${apiURL}/edge/ecommerce/woocommerce-callback-url`,
    shopifyConnectCallback: (params) =>
      `${apiURL}/edge/ecommerce/shopify-callback-url${params}`,
    validateShopifyTokenURL: () =>
      `${apiURL}/edge/ecommerce/validate-shopify-installation-request`,
    shopifyProjectList: () => `${apiURL}/edge/ecommerce/get-available-projects`,
    finalizeShopifyURL: (projectId) =>
      `${apiURL}/edge/ecommerce/projects/${projectId}/finalize-shopify-installation-request`,
    shopifyWidgetPlugin: (projectId) =>
      `${apiURL}/edge/ecommerce/projects/${projectId}/install-shopify-widget-channel`,
  },
  settings: {
    teamInfo: (teamId) => `${apiURL}/api/bots/projects/${teamId}`,
    teamInvite: (teamId) =>
      `${apiURL}/api/bots/projects/${teamId}/send-invitation`,
    pendingTeam: () => `${apiURL}/api/bots/projects/pending`,
    userActionInTeam: (teamId, userId) =>
      `${apiURL}/api/bots/projects/${teamId}/access/${userId}`,
    agentAccess: (teamId) => `${apiURL}/api/bots/projects/${teamId}/access`,
    agentAccessTag: (teamId) =>
      `${apiURL}/api/bots/projects/${teamId}/access-tags`,
    agentAccessTagAction: (teamId, groupId) =>
      `${apiURL}/api/bots/projects/${teamId}/access-tags/${groupId}`,
    agentRoles: (teamId) => `${apiURL}/api/bots/projects/${teamId}/roles`,
    share: (teamId) => `${apiURL}/api/bots/projects/${teamId}/share`,
    updateCountry: (teamId) =>
      `${apiURL}/api/bots/projects/${teamId}/update-country`,
    updateTimezone: (teamId) =>
      `${apiURL}/api/bots/projects/${teamId}/update-timezone`,
    users: (teamId) => `${apiURL}/api/bots/projects/${teamId}/users`,
    sendMultipleInvitation: (id) =>
      `${apiURL}/api/bots/projects/${id}/send-team-invitation`,
    businessHour: (teamId) => `${apiURL}/api/bots/projects/${teamId}`,
    holidayList: (teamId) => `${apiURL}/api/bots/projects/${teamId}`,
    tags: (projectId) => `${apiURL}/api/crm/projects/${projectId}/ticket-tags`,
    deleteTag: (projectId) =>
      `${apiURL}/edge/crm/projects/${projectId}/delete-tags`,
    editTag: (projectId, tagId) =>
      `${apiURL}/edge/crm/projects/${projectId}/ticket-tags/${tagId}`,
    cannedResponse: (projectId) =>
      `${apiURL}/api/crm/projects/${projectId}/canned-responses`,
    getOrCreateWebhook: (projectId) =>
      `${apiURL}/stable/lookingglass/teams/${projectId}/triggers`,
    deleteOrEditWebhook: (projectId, webhookId) =>
      `${apiURL}/stable/lookingglass/teams/${projectId}/triggers/${webhookId}`,
    editCannedResponse: (projectId, cannedResponseId) =>
      `${apiURL}/api/crm/projects/${projectId}/canned-responses/${cannedResponseId}`,
    deleteCannedResponse: (projectId, cannedResponseId) =>
      `${apiURL}/api/crm/projects/${projectId}/canned-responses/${cannedResponseId}`,
    bulkDeleteCannedResponse: (projectId) =>
      `${apiURL}/api/crm/projects/${projectId}/canned-responses/bulk-delete`,
    getChatBotSettings: (projectId) =>
      `${apiURL}/api/bots/projects/${projectId}/get-chatbot-settings`,
    updateChatBotSettings: (projectId) =>
      `${apiURL}/api/bots/projects/${projectId}/update-chatbot-settings`,
    updatetagMandatory: (projectId) =>
      `${apiURL}/api/bots/projects/${projectId}`,
    getTicketConfigurationSettings: (projectId) =>
      `${apiURL}/api/bots/projects/${projectId}/get-ticket-configuration`,
    updateTicketConfigurationSettings: (projectId) =>
      `${apiURL}/api/bots/projects/${projectId}/update-ticket-configuration`,
  },
  misc: {
    geoLocation: () =>
      `https://extreme-ip-lookup.com/json/?key=${process.env.REACT_APP_EXTREME_IP_LOOKUP_KEY}`,
    countryList: () => `${apiURL}/api/accounts/country-list`,
    timezoneList: () => `${apiURL}/api/accounts/timezone-list`,
    facebookAppId: () => process.env.REACT_APP_LOGIN_APP_ID,
    image: () => `${apiURL}/api/bots/upload/image`,
    video: () => `${apiURL}/api/bots/upload/video`,
    file: () => `${apiURL}/api/bots/upload/document`,
    fileUpload: () => `${apiURL}/api/bots/upload-document`,
    webChatSdkUrl: () =>
      status === 'production'
        ? 'https://webchat.getalice.ai'
        : 'https://webchat-stage.getalice.ai',
    facebookTestUrl: () =>
      status === 'production'
        ? 'https://m.me/108768877336530'
        : 'https://m.me/104980237682564',
    datalabPublicUrl: (datalabId) =>
      `${apiURL}/api/bots/labs/${datalabId}/submission/public`,
  },
  integration: {
    getIntegratedNlpList: (teamId, apiVersion = 'api') =>
      `${apiURL}/${apiVersion}/nlp/projects/${teamId}/integrations/list`,
    getIntegratedOthersList: (teamId) =>
      `${apiURL}/api/other-integrations/projects/${teamId}/list`,
    getSingleNlpData: (teamId, integraionId) =>
      `${apiURL}/api/nlp/projects/${teamId}/integrations/${integraionId}/retrieve`,
    createNlpIntegration: (teamId) =>
      `${apiURL}/api/nlp/projects/${teamId}/integrations/create`,
    updateNlpIntegration: (teamId, integrationId) =>
      `${apiURL}/api/nlp/projects/${teamId}/integrations/${integrationId}/update`,
    deleteNlpIntegration: (teamId, integrationId) =>
      `${apiURL}/api/nlp/projects/${teamId}/integrations/${integrationId}/delete`,

    getSingleChannelData: (teamId, channelId) =>
      `${apiURL}/api/channels/projects/${teamId}/get-channel/${channelId}`,
    getIntegratedChannelList: (teamId) =>
      `${apiURL}/edge/channels/projects/${teamId}/get-channels`,
    handleChannelPublishStatus: (teamId, channelId) =>
      `${apiURL}/api/channels/projects/${teamId}/edit-publish-status/${channelId}`,
    handleChannelDisconnect: (teamId, channelId) =>
      `${apiURL}/api/channels/projects/${teamId}/disconnect-channel-integration/${channelId}`,
    handleChannelRemove: (teamId, channelId) =>
      `${apiURL}/api/channels/projects/${teamId}/remove-channel-integration/${channelId}`,
    handleChannelEdit: (teamId, channelId) =>
      `${apiURL}/edge/channels/projects/${teamId}/edit-channel/${channelId}`,
    handleChannelReconnect: (teamId, channelId) =>
      `${apiURL}/api/channels/projects/${teamId}/reconnect-channel-integration/${channelId}`,

    generateOtherChannelDisconnectURL: (teamId, channelId) =>
      `${apiURL}/api/other-integrations/projects/${teamId}/disconnect/${channelId}`,
    generateOtherChannelEditURL: (teamId, channelId) =>
      `${apiURL}/api/other-integrations/projects/${teamId}/edit/${channelId}`,
    generateOtherChannelReconnectURL: (teamId, channelId) =>
      `${apiURL}/api/other-integrations/projects/${teamId}/reconnect/${channelId}`,

    createChannelIntegrations: (channelType, teamId) =>
      `${apiURL}/edge/channels/projects/${teamId}/${channelType}/create`,
    chatbotSettings: (channelId) =>
      `${apiURL}/api/channels/${channelId}/chatbot-settings`,

    instagramMessengerChannelConnectionValidation: (channelType, teamId) =>
      `${apiURL}/api/channels/projects/${teamId}/${channelType}/validate-ig-messenger-connection`,
    createBetterDocs: (teamId, betterDocsId) =>
      `${apiURL}/api/other-integrations/projects/${teamId}/connect`,
    handleBetterDocsEdit: (teamId, betterDocsId) =>
      `${apiURL}/api/other-integrations/projects/${teamId}/edit/${betterDocsId}`,
    handleBetterDocsRemove: (teamId, betterDocsId) =>
      `${apiURL}/api/other-integrations/projects/${teamId}/disconnect/${betterDocsId}`,
    handleRetriveBetterdocs: (teamId, betterDocsId) =>
      `${apiURL}/api/other-integrations/projects/${teamId}/retrieve/${betterDocsId}`,

    handleLiveChatMigration: (channelId) =>
      `${apiURL}/edge/channels/${channelId}/migrate-to-livechat`,

    createWhatsappTemplateMessage: (channelId) =>
      `${apiURL}/api/channels/${channelId}/create-whatsapp-message-template`,
    editWhatsappTemplateMessage: (channelId, templateId) =>
      `${apiURL}/api/channels/${channelId}/edit-whatsapp-message-template/${templateId}`,
    deleteWhatsappTemplateMessage: (channelId, templateId) =>
      `${apiURL}/api/channels/${channelId}/delete-whatsapp-message-template/${templateId}`,
    customEmailDataVerify: (projectId) =>
      `${apiURL}/edge/channels/projects/${projectId}/custom-email/verify`,
    getDynamicIntegrationList: () => `${apiURL}/api/other-integrations/list`,
    createOtherDynamicIntegration: (project_id) =>
      `${apiURL}/api/other-integrations/projects/${project_id}/connect`,
    zidIntegration: () =>
      `${apiURL}/edge/ecommerce/connect-zid-with-ecommerce-integration`,
    updateWhatsAppAccountInfo: (platformId) =>
      `${apiURL}/stable/channels/${platformId}/update-whatsapp-account-information`,

    multiEcommerceData: (teamId) =>
      `${apiURL}/edge/ecommerce/projects/${teamId}/get-ecommerce-data`,
    getEcommerceChannelPreferences: (teamId, alice_store_id) =>
      `${apiURL}/edge/ecommerce/projects/${teamId}/get-ecommerce-platform-preferences?alice_store_id=${alice_store_id}`,
    connectEcommercePlatform: (teamId) =>
      `${apiURL}/edge/ecommerce/projects/${teamId}/connect-ecommerce-platform`,
    disconnectEcommercePlatform: (teamId) =>
      `${apiURL}/edge/ecommerce/projects/${teamId}/disconnect-ecommerce-platform`,
    editStoreName: (teamId) =>
      `${apiURL}/edge/ecommerce/projects/${teamId}/update/store`,
  },
  inbox: {
    tickets: (apiVersion, teamId) =>
      `${apiURL}/${apiVersion}/crm/projects/${teamId}/tickets`,
    agentGroups: (apiVersion, teamId) =>
      `${apiURL}/${apiVersion}/bots/projects/${teamId}/groups`,
    getAllTicketTags: (apiVersion, teamId) =>
      `${apiURL}/${apiVersion}/crm/projects/${teamId}/ticket-tags`,
    assignableAgentsByAccess: (apiVersion, teamId) =>
      `${apiURL}/${apiVersion}/bots/projects/${teamId}/access`,
    updateTicketTag: (apiVersion, ticketId) =>
      `${apiURL}/${apiVersion}/crm/tickets/${ticketId}/tag`,
    createTicketTag: (apiVersion, projectId) =>
      `${apiURL}/${apiVersion}/crm/projects/${projectId}/ticket-tags`,
    ticketConversation: (apiVersion, ticketId, parameter) =>
      `${apiURL}/${apiVersion}/crm/tickets/${ticketId}/get-conversation?${parameter}`,
    botConversation: (apiVersion, customer_id, parameter) =>
      `${apiURL}/${apiVersion}/crm/customers/${customer_id}/get-bot-conversation?${parameter}`,
    getTicketFilterData: (projectId, apiVersion = 'edge') =>
      `${apiURL}/${apiVersion}/crm/projects/${projectId}/get-inbox-preset-filters`,
    updateTicketFilterData: (projectId, apiVersion = 'edge') =>
      `${apiURL}/${apiVersion}/crm/projects/${projectId}/update-inbox-preset-filters`,
    pinTicket: (apiVersion, ticketId) =>
      `${apiURL}/${apiVersion}/crm/tickets/${ticketId}/action-pin`,
    ticketResolve: (apiVersion, ticketId) =>
      `${apiURL}/${apiVersion}/crm/tickets/${ticketId}/action-resolve`,
    ticketHoldAction: (apiVersion, ticketId) =>
      `${apiURL}/${apiVersion}/crm/tickets/${ticketId}/action-hold`,
    assignTicket: (ticketId) =>
      `${apiURL}/api/crm/tickets/${ticketId}/action-assign`,
    assignBotTicket: (customer_id) =>
      `${apiURL}/api/crm/customers/${customer_id}/action-bot-assign`,
    sendMessengerChat: (apiVersion, ticketId) =>
      `${apiURL}/${apiVersion}/crm/tickets/${ticketId}/messenger-chat`,
    reopenTicket: (apiVersion, ticketId) =>
      `${apiURL}/${apiVersion}/crm/tickets/${ticketId}/action-reopen`,
    searchCustomer: (apiVersion, projectId, parameter) =>
      `${apiURL}/${apiVersion}/crm/projects/${projectId}/search-tickets?search=${parameter}`,
    customerInformation: (apiVersion, customerId) =>
      `${apiURL}/${apiVersion}/bots/customers/${customerId}/meta`,
    sendFeedThread: (apiVersion, ticketId) =>
      `${apiURL}/${apiVersion}/crm/tickets/${ticketId}/feed-thread`,
    sendGmailThread: (apiVersion, ticketId) =>
      `${apiURL}/${apiVersion}/crm/tickets/${ticketId}/send-email`,
    whatsappTemplateDetails: (apiVersion, templateId, channelId) =>
      `${apiURL}/${apiVersion}/crm/channels/${channelId}/get-whatsapp-template-attributes/${templateId}`,
    getWhatsAppMesssageTemplates: (platformId) =>
      `${apiURL}/stable/channels/${platformId}/get-whatsapp-message-templates`,
    getSavedReplies: (apiVersion, projectId) =>
      `${apiURL}/${apiVersion}/crm/projects/${projectId}/canned-responses`,
    customerAttribute: (apiVersion, customerId) =>
      `${apiURL}/${apiVersion}/crm/customers/${customerId}/attributes`,
    customerAttributeUpdate: (apiVersion, customerId) =>
      `${apiURL}/${apiVersion}/crm/customers/${customerId}/update`,
    createWhatsAppTicket: (apiVersion, teamId) =>
      `${apiURL}/${apiVersion}/crm/projects/${teamId}/create-whatsapp-ticket`,
    getWhatsappCustomerList: (apiVersion, teamId, platform_id, search_name) =>
      `${apiURL}/${apiVersion}/crm/projects/${teamId}/search-whatsapp-customers?platform_id=${platform_id}&search=${search_name}`,
    projectAttributes: (apiVersion, teamId) =>
      `${apiURL}/${apiVersion}/crm/projects/${teamId}/attributes`,
    bulkAction: (apiVersion, projectId) =>
      `${apiURL}/${apiVersion}/crm/projects/${projectId}/bulk-action`,
    ticketsBadgeCount: (apiVersion, projectId) =>
      `${apiURL}/${apiVersion}/crm/projects/${projectId}/tickets-badge-count`,
    attachmentData: (apiVersion, url) =>
      `${apiURL}/${apiVersion}/crm/download/get-attachment-data?url=${url}`,
    groupMembers: (apiVersion, customer_id) =>
      `${apiURL}/${apiVersion}/crm/groups/${customer_id}/get-group-members`,
    searchCustomerByPrimaryId: (projectId, apiVersion = 'api') =>
      `${apiURL}/${apiVersion}/crm/projects/${projectId}/search-customers`,
    createTicketByPrimaryId: (projectId, apiVersion = 'api') =>
      `${apiURL}/${apiVersion}/crm/projects/${projectId}/create-ticket`,
    slaCreation: (projectId, apiVersion = 'api') =>
      `${apiURL}/${apiVersion}/crm/projects/${projectId}/sla-policies`,
    fetchSLAPolicy: (projectId, apiVersion = 'api', offset, limit) =>
      `${apiURL}/${apiVersion}/crm/projects/${projectId}/sla-policies?limit=${limit}&offset=${offset}`,
    slaUpdate: (projectId, slaId, apiVersion = 'api') =>
      `${apiURL}/${apiVersion}/crm/projects/${projectId}/sla-policies/${slaId}`,
    slaDelete: (projectId, slaId, apiVersion = 'api') =>
      `${apiURL}/${apiVersion}/crm/projects/${projectId}/sla-policies/${slaId}`,
    searchSLAPolicy: (projectId, apiVersion = 'api', offset, limit, name) =>
      `${apiURL}/${apiVersion}/crm/projects/${projectId}/sla-policies?limit=${limit}&offset=${offset}&name=${name}`,
    setTicketPriority: (apiVersion = 'stable', ticketId) =>
      `${apiURL}/${apiVersion}/crm/tickets/${ticketId}/set-priority`,
  },
  inboxEcommerce: {
    cart: (apiVersion, customerId) =>
      `${apiURL}/${apiVersion}/ecommerce/customers/${customerId}`,
    ecommerceCustomerDetails: (apiVersion, customerId) =>
      `${apiURL}/${apiVersion}/ecommerce/customers/${customerId}/ecommerce-info`,
    cartLink: (apiVersion, customerId) =>
      `${apiURL}/${apiVersion}/ecommerce/customers/${customerId}/get-cart-link`,
    products: (apiVersion, teamId) =>
      `${apiURL}/${apiVersion}/ecommerce/projects/${teamId}/products`,
    updateCart: (apiVersion, customerId) =>
      `${apiURL}/${apiVersion}/ecommerce/customers/${customerId}/update-cart`,
    variant: (apiVersion, teamId, productId) =>
      `${apiURL}/${apiVersion}/ecommerce/projects/${teamId}/products/${productId}/variations`,
    createOrder: (apiVersion, projectId) =>
      `${apiURL}/${apiVersion}/ecommerce/projects/${projectId}/create-order`,
    updateOrder: (apiVersion, teamId, orderId) =>
      `${apiURL}/${apiVersion}/ecommerce/projects/${teamId}/update-order/${orderId}`,
    getOrder: (apiVersion, teamId, orderId) =>
      `${apiURL}/${apiVersion}/ecommerce/projects/${teamId}/get-order/${orderId}`,
    cancelOrder: (apiVersion, teamId, orderId) =>
      `${apiURL}/${apiVersion}/ecommerce/projects/${teamId}/cancel-order/${orderId}`,
    recommendProducts: (apiVersion, customerId) =>
      `${apiURL}/${apiVersion}/ecommerce/customers/${customerId}/recommend-products`,
    calculateDraftOrder: (apiVersion, teamId) =>
      `${apiURL}/${apiVersion}/ecommerce/projects/${teamId}/calculate-draft-order`,
    paymentMethod: (apiVersion, teamId) =>
      `${apiURL}/${apiVersion}/ecommerce/projects/${teamId}/payment-methods`,
    shippingMethod: (apiVersion, teamId) =>
      `${apiURL}/${apiVersion}/ecommerce/projects/${teamId}/shipping-methods`,
    getWocommerceCoupons: (apiVersion, teamId) =>
      `${apiURL}/${apiVersion}/ecommerce/projects/${teamId}/coupons`,
    ecommerceCustomerList: (apiVersion, teamId, searchQuery) =>
      `${apiURL}/${apiVersion}/ecommerce/projects/${teamId}/customer-list?search=${searchQuery}`,
    connectEcommerceCustomer: (apiVersion, teamId) =>
      `${apiURL}/${apiVersion}/ecommerce/projects/${teamId}/link-customer`,
    disconnectEcommerceCustomer: (apiVersion, teamId) =>
      `${apiURL}/${apiVersion}/ecommerce/projects/${teamId}/unlink-customer`,
    orderList: (apiVersion, customerId) =>
      `${apiURL}/${apiVersion}/ecommerce/customers/${customerId}/order-list`,
    refundOrder: (apiVersion, teamId, orderId) =>
      `${apiURL}/${apiVersion}/ecommerce/projects/${teamId}/refund-order/${orderId}`,
    ecommerceCountryList: (projectId) =>
      `${apiURL}/edge/ecommerce/projects/${projectId}/countries`,
    ecommerceCityList: (projectId, countryId) =>
      `${apiURL}/edge/ecommerce/projects/${projectId}/countries/${countryId}/cities`,
  },
  broadcast: {
    broadcastList: (projectId) =>
      `${apiURL}/stable/broadcast/projects/${projectId}/list`,
    createBroadcast: (projectId) =>
      `${apiURL}/stable/broadcast/projects/${projectId}/create`,
    updateBroadcast: (projectId) =>
      `${apiURL}/stable/broadcast/projects/${projectId}/update`,
    deleteBroadcast: (projectId) =>
      `${apiURL}/stable/broadcast/projects/${projectId}/delete`,
    broadcastDetails: (projectId) =>
      `${apiURL}/stable/broadcast/projects/${projectId}/details`,
    addCredits: (projectId) =>
      `${apiURL}/stable/broadcast/projects/${projectId}/add-credits`,
    sendTestMessage: (projectId) =>
      `${apiURL}/stable/broadcast/projects/${projectId}/send-test-message`,
    downloadCampaignReport: (projectId) =>
      `${apiURL}/stable/broadcast/projects/${projectId}/download-campaign-report`,
    downloadAudienceCsv: (projectId) =>
      `${apiURL}/stable/broadcast/projects/${projectId}/download-audience-data`,
    whatsappAttributelist: (projectId) =>
      `${apiURL}/stable/broadcast/projects/${projectId}/whatsapp-broadcast-attributes`,
  },
  automationWorkflow: {
    getAllTriggerData: (ecommerceTag) =>
      `${automationWorkflowUrl}/api/triggers/?ecommerce_tag=${ecommerceTag}`,
    getSelectedTriggerDetails: (ecommerceTag, triggerCode) =>
      `${automationWorkflowUrl}/api/triggers/get-specific-trigger-data?ecommerce_tag=${ecommerceTag}&trigger_code=${triggerCode}`,
    getEcommerceProducts: (teamId, search) =>
      `${apiURL}/edge/ecommerce/projects/${teamId}/products?search=${search}`,
    whatsappTemplates: (platformId) =>
      `${apiURL}/stable/channels/${platformId}/get-whatsapp-message-templates`,
    createAutomationFlow: (teamId, search, limit) =>
      `${automationWorkflowUrl}/api/user-action-flow/create`,
    getUserActionFlowList: (projectId, offset, limit) =>
      `${automationWorkflowUrl}/api/user-action-flow/?project_id=${projectId}&offset=${offset}&limit=${limit}`,
    getSelectedActionFlow: (flowId) =>
      `${automationWorkflowUrl}/api/user-action-flow/${flowId}/`,
    updateSelectedUserActionFlowInfo: (flowId) =>
      `${automationWorkflowUrl}/api/user-action-flow/${flowId}/update`,
    getSelectedActionFlowLogListData: (flowId, offset, limit) =>
      `${automationWorkflowUrl}/api/history/?user_action_flow_id=${flowId}&offset=${offset}&limit=${limit}`,
    updateSelectedActionFlow: (flowId) =>
      `${automationWorkflowUrl}/api/user-action-flow/${flowId}/update`,
    getCommonComponentsData: () =>
      `${automationWorkflowUrl}/api/components/common-components/`,
    deleteSelectedWorkflow: (flowId) =>
      `${automationWorkflowUrl}/api/user-action-flow/${flowId}/delete`,
    getSingleWorkflowLogDetails: (flowId) =>
      `${automationWorkflowUrl}/api/user-action-flow/${flowId}/user-flow-detailed-logs`,
  },
};

export default config;
