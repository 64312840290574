import { toast } from 'libraryV2/ui/use-toast';
import {
  Loading,
  format,
  DataTable,
  useEffect,
  DataLabHeader,
  UTILITY_METHODS,
  createDynamicColumns,
} from './../export';

import useDatalab from '../hooks/useDatalab';

const DataLabTable = () => {
  const {
    labId,
    lab_fields,
    pageChange,
    clearState,
    datalabEntries,
    hasReadAccess,
    hasWriteAccess,
    hasDeleteAccess,
    isDatalabLoading,
    deleteBulkDatalabList,
    fetchDatalabInfoAndEntries,
  } = useDatalab();

  // const [datalabEntries,setDatalabEntries] = useState([])

  useEffect(() => {
    const getEntries = async () => {
      try {
        let today = UTILITY_METHODS.getThirtyDaysDateRange();
        let start = format(today.initialFromDate, 'yyyy-MM-dd');
        let end = format(today.initialToDate, 'yyyy-MM-dd');
        let dateRange = { start: start, end: end };
        await fetchDatalabInfoAndEntries({
          dateRange,
          pageChange,
        });
      } catch (error) {
        toast({ variant: 'destructive', description: 'Failed to fetch!' });
      }
    };

    getEntries();
    return clearState;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addActionsColumn = false;
  const columns = createDynamicColumns(
    labId,
    datalabEntries,
    lab_fields,
    hasReadAccess,
    hasWriteAccess,
    hasDeleteAccess,
    deleteBulkDatalabList,
    addActionsColumn
  );

  const renderNewDatalabList = () => (
    <div className='relative flex flex-col'>
      <div className='sticky top-0 z-10 bg-white'>
        <DataLabHeader />
      </div>
      <div className='p-4 bg-white ml-[1px] min-h-[93vh]'>
        {isDatalabLoading ? (
          <Loading />
        ) : (
          <DataTable data={datalabEntries} columns={columns} />
        )}
      </div>
    </div>
  );

  return renderNewDatalabList();
};

export default DataLabTable;
