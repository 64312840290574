import React, { ChangeEvent, useRef } from 'react';
import { Input } from 'libraryV2/ui/input';
import { Label } from 'libraryV2/ui/label';
import { Badge } from 'libraryV2/ui/badge';

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'libraryV2/ui/tooltip';

interface Props {
  name?: string;
  label?: string;
  title?: string;
  className?: string;
  style?: any;
  labelStyle?: any;
  value: string | number | readonly string[] | undefined;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: any) => void;
  placeholder?: string;
  required?: boolean;
  focus?: boolean;
  error?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  characterLimit?: number | string | boolean;
}

const InputText: React.FC<Props> = ({
  name = '',
  label = '',
  title = '',
  className = 'grid items-center gap-1.5',
  style = { width: '268px' },
  labelStyle = {},
  value,
  onChange,
  placeholder = 'Enter label',
  required = false,
  focus = false,
  error = false,
  disabled = false,
  readOnly = false,
  characterLimit = false,
  onBlur,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const defaultBadgeInputDivStyle =
    'flex justify-between items-center border rounded-md sm:text-sm';

  const handleOnCharacterLimitClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();

      // get class names and add border color #04b25f
      const classNames = inputRef.current.className;
      inputRef.current.className = `${classNames} border-primary`;
    }
  };

  const handleCharacterLimitOnBlur = () => {
    if (inputRef.current) {
      inputRef.current.className = defaultBadgeInputDivStyle;
    }
  };

  const handleOnChangeCheckCharacterLimit = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = e.target;
    if (value?.toString().length <= Number(characterLimit) && onChange) {
      onChange(e);
    } else {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  return (
    <div className={className} style={style}>
      {label && (
        <Label htmlFor='labelForCustomer' style={{ ...labelStyle }}>
          {label}{' '}
          {required && (
            <span style={{ color: '#FF2323', marginLeft: '1px' }}>*</span>
          )}
        </Label>
      )}
      {readOnly ? (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger style={{ width: '100%' }}>
              <Input
                id={name}
                type='text'
                name={name}
                title={title}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                placeholder={placeholder}
                className={`${
                  error ? 'border-red-600' : ''
                } focus:ring-primary focus:border-primary sm:text-sm`}
                autoFocus={focus}
                disabled={disabled}
                readOnly={readOnly}
                style={{ cursor: readOnly ? 'not-allowed' : 'text' }}
              />
            </TooltipTrigger>
            <TooltipContent>
              <p>This field is not editable.</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      ) : (
        <>
          {characterLimit ? (
            <div
              className={` ${defaultBadgeInputDivStyle} ${
                error
                  ? 'border-red-600 focus:ring-red-600 focus:border-red-600'
                  : 'active:ring-primary active:border-primary'
              }`}
              style={{ width: '100%', height: '36px' }}
              ref={inputRef}
              onClick={handleOnCharacterLimitClick}
              onBlur={handleCharacterLimitOnBlur}
            >
              <Input
                id={name}
                type='text'
                name={name}
                title={title}
                value={value}
                onChange={handleOnChangeCheckCharacterLimit}
                onBlur={onBlur}
                placeholder={placeholder}
                required={required}
                className={`border-0 sm:text-sm shadow-none mt-0 h-full w-full`}
                autoFocus={focus}
                disabled={disabled}
              />

              <Badge
                variant='ghost'
                className='rounded-md bg-gray-100 mr-2'
                title='Character limit'
              >
                {value?.toString().length}/{characterLimit}
              </Badge>
            </div>
          ) : (
            <Input
              id={name}
              type='text'
              name={name}
              title={title}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              placeholder={placeholder}
              required={required}
              className={`${
                error
                  ? 'border-red-600 focus:ring-red-600 focus:border-red-600'
                  : 'focus:ring-primary focus:border-primary'
              } mt-0 sm:text-sm`}
              autoFocus={focus}
              disabled={disabled}
            />
          )}
        </>
      )}
      {typeof error === 'string' && (
        <span className='text-xs text-red-600'>{error}</span>
      )}
    </div>
  );
};

export default InputText;
