import { XIcon } from 'lucide-react';
import {
  React,
  Listbox,
  ChevronDownIcon,
  ChevronRightIcon,
} from 'pages/automationWorkflow/export';
import { IOrderItem, IContactItem } from 'pages/automationWorkflow/interface';

type Props = {
  categoryOptions: string[];
  categoryDetails: IOrderItem[] | IContactItem[];
  activeValue: {
    value: string;
    category: string;
  };
  updateSelectedTriggerInfoLeft: (data: string) => void;
  updateSelectedTriggerInfoRight: (data: string) => void;
};

const MultiListSelector: React.FC<Props> = ({
  categoryOptions,
  categoryDetails,
  activeValue,
  updateSelectedTriggerInfoLeft,
  updateSelectedTriggerInfoRight,
}) => {
  const [openListboxOption, setOpenListboxOption] =
    React.useState<boolean>(false);

  return (
    <Listbox
      value={{ id: '1', label: 'Select phone number variable' }}
      onChange={(data) => console.log(data)}
    >
      <div className='relative w-full'>
        <Listbox.Button
          className='relative py-2 px-4 text-gray-500 text-sm w-full text-left bg-white border  rounded-md cursor-pointer focus:outline-none'
          onClick={() => setOpenListboxOption(!openListboxOption)}
        >
          {activeValue?.value !== '' ? (
            <span className='bg-indigo-50 px-2 py-1 rounded text-xs font-medium text-indigo-400 '>
              {`{{${activeValue?.value}}}`}{' '}
              <XIcon
                className='w-3 h-3 text-indigo-800 ml-2 inline'
                onClick={() => {
                  updateSelectedTriggerInfoRight('');
                }}
              />{' '}
            </span>
          ) : (
            <span className='px-2 py-1 text-sm font-regular text-gray-500'>
              Select variables
            </span>
          )}

          <span className='absolute inset-y-0 right-0 flex items-center pr-2 ml-3 pointer-events-none'>
            <ChevronDownIcon
              className='w-4 h-4 text-gray-400'
              aria-hidden='true'
            />
          </span>
        </Listbox.Button>

        {openListboxOption && (
          <div className='absolute z-10 w-full mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-56 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
            <div className='grid grid-cols-5 grid-flow-col'>
              <div className='col-span-2 border-r'>
                {categoryOptions.map((item: string, index: number) => (
                  <div className='border-b p-2 ' key={index}>
                    <div
                      className={`${
                        activeValue?.category === item
                          ? 'bg-[#E7F8F0] text-[#04A056]'
                          : 'bg-white'
                      } flex justify-between items-center px-4 py-2 cursor-pointer hover:bg-[#E7F8F0] text-gray-700 hover:text-[#04A056] rounded-md`}
                      onClick={() => updateSelectedTriggerInfoLeft(item)}
                    >
                      <div className='flex items-center'>
                        <h3 className='ml-3 font-medium text-base'>{item}</h3>
                      </div>
                      <div>
                        <ChevronRightIcon className='w-5 h-5 text-gray-500' />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className='col-span-3'>
                {categoryDetails.length > 0 &&
                  categoryDetails.map(
                    (item: IOrderItem | IContactItem, index: number) => (
                      <div className='border-b p-2 ' key={index}>
                        <div
                          className={`${
                            activeValue?.value === item?.name
                              ? 'bg-gray-100'
                              : 'bg-white'
                          } flex justify-between items-center px-4 py-2 cursor-pointer hover:bg-gray-100 rounded-md`}
                        >
                          <div className='flex'>
                            <div
                              className='text-gray-600'
                              onClick={() => {
                                updateSelectedTriggerInfoRight(item?.name);
                                setOpenListboxOption(false);
                              }}
                            >{`{{${item?.name}}}`}</div>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                {categoryDetails.length === 0 && (
                  <div className='flex justify-center items-center h-full'>
                    No Data Found
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </Listbox>
  );
};

export default MultiListSelector;
