import {
  Checkbox,
  ColumnDef,
  DataTableRowActions,
  DataTableColumnHeader,
} from '../../../export';

import { Badge } from '../../../../../libraryV2/ui/badge';
import { getLocalizedTimeDateFormat } from '../../../utils';

type LabsDataType = Record<string, any>;

function createDynamicColumns(
  labId: string,
  labsData: LabsDataType[] = [],
  lab_fields: any[] = [],
  hasReadAccess: boolean,
  hasWriteAccess: boolean,
  hasDeleteAccess: boolean,
  deleteBulkDatalabList: (labId: string, rowIds: string[]) => void,
  addActionsColumn: boolean = true
) {
  // Safeguard for labsData being null or undefined
  if (!Array.isArray(labsData) || labsData.length === 0) {
    return [];
  }

  const columnList = () => {
    try {
      // Safeguard for lab_fields being null/undefined or not an array
      let slugs = Array.isArray(lab_fields)
        ? lab_fields.map((item: { slug?: string }) => item.slug || '')
        : [];

      let metaSlug = ['source', 'created_by', 'created_at'];

      // Check if any metaSlug exists in slugs
      const uniqueMetaSlug = metaSlug.filter((meta) => !slugs.includes(meta));

      // Combine the unique metaSlug with the slugs
      return [...uniqueMetaSlug, ...slugs];
    } catch (error) {
      console.error('Error generating column list:', error);
      return ['source', 'created_by', 'created_at'];
    }
  };

  const truncateText = (text: string, maxLength: number) => {
    try {
      if (text?.length <= maxLength) {
        return text;
      }
      const extensionMatch = text.match(/\.(\w+)$/);
      if (extensionMatch) {
        const extension = extensionMatch[0];
        const baseLength = maxLength - extension.length;
        return `${text.slice(0, baseLength)}...${extension}`;
      }
      return `${text.slice(0, maxLength)}...`;
    } catch (err) {
      console.error('Error truncating text:', err);
      return '--';
    }
  };

  const renderViewForMultiselectField = (
    optionsList: any[] = [],
    choices: any[] = []
  ) => {
    try {
      const getColor = (name: string) => {
        const choice = choices?.find((choice) => choice.name === name);
        return choice ? choice.color : 'text-textPrimary';
      };

      const formattedOptionsList = optionsList.map((option) => {
        let name: string;
        let color: string;

        if (typeof option === 'object' && option !== null && 'name' in option) {
          name = truncateText(option.name, 17);
          color = option.color || 'text-textPrimary';
        } else {
          name = typeof option === 'string' ? truncateText(option, 17) : '--';
          color = getColor(option);
        }

        return { name, color };
      });

      return (
        <Badge list={formattedOptionsList} variant={'datalab'} maxVisible={1} />
      );
    } catch (error) {
      console.error('Error rendering multiselect field:', error);
      return <Badge variant={'datalab'} list={[]} maxVisible={1} />;
    }
  };

  const createCellRenderer =
    (key: string) =>
    ({ row }: any) => {
      try {
        let cellValue = row.getValue(key);
        let fieldDetails = lab_fields?.find((field: any) => field.slug === key);
        let cellType = fieldDetails?.type;

        if (cellValue === null || cellValue === '' || cellValue === undefined) {
          return <div>--</div>;
        }
        if (key === 'created_by' || key === 'source') {
          return (
            <span className='text-sm font-medium text-textPrimary'>
              {truncateText(cellValue, 17)}
            </span>
          );
        }

        if (cellType === 'datetime') {
          return getLocalizedTimeDateFormat(
            cellValue,
            'en',
            new Date(cellValue).getTime() > 0
              ? 'MMM DD YYYY, hh:mm A'
              : 'MMM DD, YYYY'
          );
        }

        if (cellType === 'date') {
          return getLocalizedTimeDateFormat(cellValue, 'en', 'MMM DD, YYYY');
        }
        if (cellType === 'multiselect') {
          return renderViewForMultiselectField(
            cellValue,
            fieldDetails?.choices
          );
        }
        if (cellType === 'singleselect') {
          return renderViewForMultiselectField(
            [cellValue],
            fieldDetails?.choices
          );
        }

        if (typeof cellValue === 'string') {
          cellValue = truncateText(cellValue, 17);
        }
        return <div>{`${cellValue}`}</div>;
      } catch (error) {
        console.error('Error rendering cell:', error);
        return <div>--</div>;
      }
    };

  const dynamicColumns: ColumnDef<LabsDataType>[] = columnList().map((key) => ({
    accessorKey: key,
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title={key} />
    ),
    cell: createCellRenderer(key),
    enableSorting: true,
    enableHiding: true,
  }));

  // Add the select component column
  dynamicColumns.unshift({
    id: 'select',
    header: ({ table }) => (
      <Checkbox
        checked={
          table.getIsAllPageRowsSelected() ||
          (table.getIsSomePageRowsSelected() && 'indeterminate')
        }
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label='Select all'
        className='translate-y-[2px] border-gray-500'
      />
    ),
    cell: ({ row }: any) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label='Select row'
        className='translate-y-[2px] border-gray-500'
      />
    ),
    enableSorting: false,
    enableHiding: false,
  });

  // Add the actions column
  if (addActionsColumn) {
    dynamicColumns.push({
      id: 'actions',
      cell: ({ row }) => (
        <DataTableRowActions
          row={row}
          labId={labId}
          hasReadAccess={hasReadAccess}
          hasWriteAccess={hasWriteAccess}
          hasDeleteAccess={hasDeleteAccess}
          deleteBulkDatalabList={deleteBulkDatalabList}
        />
      ),
      enableSorting: false,
      enableHiding: false,
    });
  }

  return dynamicColumns;
}

export default createDynamicColumns;
