import { useDispatch, useSelector } from 'react-redux';
import authTranslationData from '../../locales/authTranslation.json';
import broadcastTranslationData from '../../locales/broadcastTranslation.json';
import dashboardTranslationData from '../../locales/dashboardTranslation.json';
import datalabTranslationData from '../../locales/datalabTranslation.json';
import inboxTranslationData from '../../locales/inboxTranslation.json';
import integrationTranslationData from '../../locales/integrationTranslation.json';
import onboardingTranslationData from '../../locales/onboardingTranslation.json';
import reportsTranslationData from '../../locales/reportsTranslation.json';
import settingsTranslationData from '../../locales/settingsTranslation.json';
import teamTranslationData from '../../locales/teamAgentTranslation.json';

import { useLocation } from '@reach/router';
import { useEffect, useState } from 'react';
import { getLanguageKey } from 'utilities/utils';

type TranslationData = { [key: string]: any }; // Define a type for translation data

const useTranslation = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch<any>();

  // preferred language saved in DB
  const dbLanguage: string = useSelector(
    (state: any) => state.auth?.dashboard_language || 'english'
  );
  const dashboardData = useSelector((state: any) => state.dashboard);

  const localStorageLanguage = localStorage.getItem('language') || 'english';

  const [dashboardLanguage, setDashboardLanguage] =
    useState<string>(localStorageLanguage);

  const isRtlLanguage = ['arabic'].includes(dashboardLanguage);

  // Restore authJsonTranslationData
  const authJsonTranslationData = { ...authTranslationData };

  const getUrlParamValue = (): string | null => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get('lang') || null;
  };

  useEffect(() => {
    if (dbLanguage !== localStorageLanguage) {
      localStorage.setItem('language', dbLanguage || 'english');
    }
    setDashboardLanguage(dbLanguage || 'english');
    // eslint-disable-next-line
  }, [dbLanguage]);

  useEffect(() => {
    const paramsValue = getUrlParamValue();
    if (!!paramsValue) {
      updateLanguage(getLanguageKey(paramsValue.trim().toLowerCase()));
    } else {
      const localStorageLanguageValue =
        localStorage.getItem('language') || 'english';
      updateLanguage(localStorageLanguageValue);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Create a script element
    const scriptTag = document.createElement('script');
    if (dashboardLanguage === 'arabic') {
      // Set the inner HTML to add the 'dir' attribute to the 'body' tag
      scriptTag.innerHTML = "document.body.setAttribute('dir', 'rtl');";

      // Append the script element to the head
      document.head.appendChild(scriptTag);
    } else {
      scriptTag.innerHTML = "document.body.setAttribute('dir', 'ltr');";
      document.head.appendChild(scriptTag);
    }
  }, [dashboardLanguage]);

  const getNavString = (): string | null => {
    switch (dashboardData?.navActiveId) {
      case 0:
        return 'dashboard';
      case 1:
        return 'inbox';
      case 2:
        return 'integration';
      case 3:
        return 'automation';
      case 4:
        return 'customer';
      case 5:
        return 'reporting';
      case 9:
        return 'broadcast';
      case 10:
        return 'data-lab';
      case -1:
        return 'settings';
      default:
        return null;
    }
  };

  /**
   * Determines the navigation string for translation based on the URL path.
   * It prioritizes the first segment for specific pages, treats certain segments with sub-pages as a single entity,
   * and defaults to the last segment otherwise.
   */
  const getNavStringFromUrl = (): string | undefined => {
    const pathSegment = pathname.split('/').filter((v) => v.length);
    const firstSegment = pathSegment[0];

    const useFirstSegmentFor = [
      'dashboard',
      'integrations',
      'settings',
      'onboarding',
      // add other if needed
    ];

    const specialSegmentsWithSubpages = [
      'data-lab',
      // Add other base segments that have sub-pages and should be treated as one
    ];

    // Check if the first segment should be used
    if (useFirstSegmentFor.includes(firstSegment)) {
      return firstSegment;
    } else {
      // Check if the path starts with any of the special segments and treat the whole path as belonging to that segment
      for (const specialSegment of specialSegmentsWithSubpages) {
        if (pathSegment.includes(specialSegment)) {
          return specialSegment;
        }
      }

      // If no special handling is needed, return the last segment
      return pathSegment[pathSegment.length - 1];
    }
  };

  const translatedFeatures = [
    'inbox',
    'settings',
    'dashboard',
    'integrations',
    'onboarding',
    'data-lab',
    'reporting',
    'broadcast',
  ];

  const getTranslationData = (): TranslationData => {
    return {
      ...onboardingTranslationData,
      ...dashboardTranslationData,
      ...inboxTranslationData,
      ...integrationTranslationData,
      ...teamTranslationData,
      ...settingsTranslationData,
      ...broadcastTranslationData,
      ...datalabTranslationData,
      ...reportsTranslationData,
    };
  };

  const tn = (num: any): string | number => {
    if (!num) return num;
    const testValue = Number(num);
    // eslint-disable-next-line eqeqeq
    if (Number.isNaN(testValue) || num != testValue) return num;
    const localFormat = dashboardLanguage === 'arabic' ? 'ar-SA' : 'en-US';
    return testValue.toLocaleString(localFormat);
  };

  const getSingleTranslatedWord = (key: string): string => {
    const translationData = getTranslationData();
    const currentTab = getNavStringFromUrl() ?? getNavString();

    if (
      !!currentTab &&
      dashboardLanguage &&
      translatedFeatures.includes(currentTab)
    ) {
      let translatedContent =
        translationData?.[currentTab]?.[dashboardLanguage]?.[
          key.trim().toLowerCase()
        ];

      return !!translatedContent ? translatedContent : key;
    }
    return key;
  };

  const getDoubleBracketsValues = (str: string, arr: string[] = []) => {
    let replacedString: string = '';
    if (typeof str === 'string')
      replacedString = str?.replace(/{{(.*?)}}/g, (_, value) => {
        arr.push(value);
        return '{{$}}';
      });
    return { replacedString, values: [...arr] };
  };

  const replaceDoubleBracketsWithValues = (str: string, arr: string[] = []) => {
    return str?.replace(/\[([^\]]+)\]/g, (_, g1) =>
      getSingleTranslatedWord(arr[g1] || g1)
    );
  };

  const t = (key: string): string => {
    if (dashboardLanguage === 'english' && typeof key === 'string') {
      const result = key.replace(/{{(.*?)}}/g, (_, value) => value);
      return result;
    }
    const translationData = getTranslationData();
    const currentTab = getNavStringFromUrl() ?? getNavString();
    const generatedKeyData = getDoubleBracketsValues(key);
    let translatedContent = '';
    if (
      !!currentTab &&
      dashboardLanguage &&
      translatedFeatures.includes(currentTab)
    ) {
      const localizedText =
        translationData?.[currentTab]?.[dashboardLanguage]?.[
          generatedKeyData?.replacedString?.trim().toLowerCase()
        ] ?? null;

      if (localizedText != null) {
        translatedContent = localizedText;
      }
    }

    if (!translatedContent || !currentTab) {
      const defaultTranslation =
        translationData?.['teamsupport']?.[dashboardLanguage]?.[
          generatedKeyData.replacedString.trim().toLowerCase()
        ];

      return !!defaultTranslation
        ? replaceDoubleBracketsWithValues(
            defaultTranslation,
            generatedKeyData.values
          )
        : key;
    } else {
      return !!translatedContent
        ? replaceDoubleBracketsWithValues(
            translatedContent,
            generatedKeyData.values
          )
        : key;
    }
  };

  const updateLanguage = async (newLanguage: string) => {
    await dispatch.auth.updateStateData({
      key: 'dashboard_language',
      value: newLanguage,
    });
  };

  const authT = (key: string): string => {
    if (!key) return key;
    const lowercaseKey = key.trim().toLowerCase();
    if (!dashboardLanguage || !authJsonTranslationData.auth) {
      return key;
    }
    const translationData = authJsonTranslationData.auth;
    const translatedContent =
      //@ts-ignore
      translationData?.[dashboardLanguage]?.[lowercaseKey];
    return translatedContent || key;
  };

  return {
    t,
    dashboardLanguage,
    setDashboardLanguage,
    authT,
    updateLanguage,
    isRtlLanguage,
    tn,
  };
};

export default useTranslation;
