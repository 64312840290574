import dayjs from 'dayjs';
import 'dayjs/locale/ar';
import 'dayjs/locale/en';
import 'dayjs/locale/es';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { PlatformInterface, SelectedTeamInterface } from 'index';
import { IDataLabField } from 'pages/datalab/interface';
import { emailChannels, monthList } from 'utilities/utils';
import {
  CustomerInfoInterface,
  IAddress,
  ICustomerOrderProperty,
  IEcommerceProductData,
  IEcommerceProductDataForUI,
  ISavedReplyAttachemnt,
  OrderFormInformationType,
  TicketFilterInterface,
  TicketInterface,
  TPlatforms,
  WhatsappTemplate,
} from '../inboxInterface';
import {
  getSavedReplyWarningByPlatform,
  SAVED_REPLY_PLATFORM_RESTRICTIONS,
  ticketFilterDefaultValue,
} from './contents';
dayjs.extend(utc);
dayjs.extend(timezone);

export const getQueryParameterValueFromFilterData = (
  key: string,
  filterData: any
) => {
  // const { tags, groups, agents, channels, startDate, endDate, priorities } = filterData;

  switch (key) {
    case 'channels':
      let channelParams = '';
      if (
        filterData.channels.length === 0 ||
        filterData.channels.includes('all')
      ) {
        return (channelParams = 'all');
      } else {
        filterData.channels.forEach((channelIds: string, index: number) => {
          if (index === filterData.channels.length - 1) {
            channelParams = channelParams + channelIds;
          } else {
            channelParams = channelParams + channelIds + ',';
          }
        });
      }
      return channelParams;

    case 'agents':
      let agentParams = '';
      if (filterData.agents.length === 0 || filterData.agents.includes('all'))
        return (agentParams = 'all');
      else {
        filterData.agents.forEach((agentsIds: string, index: number) => {
          if (index === filterData.agents.length - 1) {
            agentParams = agentParams + agentsIds;
          } else {
            agentParams = agentParams + agentsIds + ',';
          }
        });
      }
      return agentParams;

    case 'groups':
      let groupParams = '';
      if (filterData.groups.length === 0 || filterData.groups.includes('all')) {
        return (groupParams = 'all');
      } else {
        filterData.groups.forEach((groupIds: string, index: number) => {
          if (index === filterData.groups.length - 1) {
            groupParams = groupParams + groupIds;
          } else {
            groupParams = groupParams + groupIds + ',';
          }
        });
      }
      return groupParams;

    case 'tags':
      let tagParams = '';
      if (filterData.tags.length === 0 || filterData.tags.includes('all')) {
        return (tagParams = 'all');
      } else {
        filterData.tags.forEach((tagId: string, index: number) => {
          if (index === filterData.tags.length - 1) {
            tagParams = tagParams + tagId;
          } else {
            tagParams = tagParams + tagId + ',';
          }
        });
      }
      return tagParams;

    case 'priorities':
      if (!Array.isArray(filterData?.priorities)) {
        break;
      }
      let priorityParams = '';
      if (filterData.priorities.length === 0) {
        priorityParams = 'all';
        return priorityParams;
      } else {
        filterData.priorities.forEach((id: string, index: number) => {
          if (index === filterData.priorities.length - 1) {
            priorityParams = priorityParams + id;
          } else {
            priorityParams = priorityParams + id + ',';
          }
        });
      }
      return priorityParams;

    case 'startDate':
      return filterData.startDate;
    case 'endDate':
      return filterData.endDate;
    default:
      return;
  }
};

export const getTimeDateFormatWithYear = (timestamp: number | string) => {
  const getTwoDigit = (val: number) => {
    return val < 10 ? `0${val}` : val;
  };
  let date;
  if (isNaN(Number(timestamp))) {
    date = new Date(timestamp);
  } else {
    const timestampInSeconds = Number(timestamp);
    // Check if timestamp is in seconds (Unix format), then convert to milliseconds
    date = new Date(
      timestampInSeconds < 1e12 ? timestampInSeconds * 1000 : timestampInSeconds
    );
  }
  return `${getTwoDigit(date.getDate())} ${monthList[
    date.getMonth()
  ]?.substring(0, 3)} ${date.getFullYear()}, ${
    getTwoDigit(date.getHours() === 12 ? 12 : date.getHours() % 12) +
    ':' +
    getTwoDigit(date.getMinutes())
  } ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
};

export const getTimeDateFormat = (timestamp: number | string) => {
  const getTwoDigit = (val: number) => {
    return val < 10 ? `0${val}` : val;
  };
  let date;
  if (isNaN(Number(timestamp))) {
    date = new Date(timestamp);
  } else {
    const timestampInSeconds = Number(timestamp);
    // Check if timestamp is in seconds (Unix format), then convert to milliseconds
    date = new Date(
      timestampInSeconds < 1e12 ? timestampInSeconds * 1000 : timestampInSeconds
    );
  }
  return `${getTwoDigit(date.getDate())} ${monthList[
    date.getMonth()
  ]?.substring(0, 3)}, ${
    getTwoDigit(date.getHours() === 12 ? 12 : date.getHours() % 12) +
    ':' +
    getTwoDigit(date.getMinutes())
  } ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
};
export const getLocalizedTimeDateFormat = (
  timestamp: number | string,
  locale = 'en',
  dayjsDateFormat = 'DD MMM, hh:mm A'
) => {
  return dayjs(timestamp).locale(locale).format(dayjsDateFormat);
};

export const getDateFormat = (timestamp: number | string) => {
  const getTwoDigit = (val: number) => {
    return val < 10 ? `0${val}` : val;
  };
  let date;
  if (isNaN(Number(timestamp))) {
    date = new Date(timestamp);
  } else {
    //@ts-ignore
    date = new Date(timestamp * 1000);
  }
  return `${getTwoDigit(date.getDate())} ${monthList[
    date.getMonth()
  ]?.substring(0, 3)} ${getTwoDigit(date.getFullYear())}`;
};

export const convertDateStringToTimeStamp = (dateString: string) => {
  const timezone = dayjs(dateString).tz().format('z');
  const regex =
    /^(\w{3}) (\w{3} \d{2} \d{4} \d{2}:\d{2}:\d{2}) GMT([+-]\d{2})(\d{2}) \(.+\)$/i;
  const [, day, dateTime, hoursOffset, minutesOffset] =
    regex.exec(dateString) || [];
  const formattedDateTime = `${day} ${dateTime} GMT${hoursOffset}:${minutesOffset}`;
  const date = dayjs(formattedDateTime).utcOffset(timezone);
  return date.valueOf();
};

export const renderAgentStatusBadgeColor = (status: string) => {
  if (status === 'online') {
    return 'bg-green-400';
  } else if (status === 'away') {
    return 'bg-yellow-400';
  } else return 'bg-[#D1D5DB]';
};

export const filterMatcher = (filterData: any, ticketData: any) => {
  return true;
  // TODO: check if filter applied and incoming ticket staisfies the filtered conditions.
};

export const shouldInsertTicketOnQueue = (parameters: {
  userId: number;
  projectId: number;
  queueType: string;
  resolveStatus: number;
  newticketData: TicketInterface;
}) => {
  let assignedAgent = parameters.newticketData?.assigned_agent || 0;

  if (parameters.queueType === 'self')
    return assignedAgent === parameters.userId;

  return false;
};

export const shouldRemoveTicketFromQueue = (parameters: {
  userId: number;
  projectId: number;
  queueType: string;
  resolveStatus: number;
  newticketData: TicketInterface;
}) => {
  let assignedAgent = parameters.newticketData?.assigned_agent || 0;
  let assignedGroup = parameters.newticketData?.assigned_group || 0;

  if (parameters.queueType === 'all')
    return assignedAgent === 0 && assignedGroup === 0;
  if (parameters.queueType === 'self')
    return assignedAgent !== parameters.userId;
  if (parameters.queueType === 'unassigned')
    return assignedAgent > 0 || assignedGroup > 0;
  return false;
};

/**
 * shouldUpdateTicketListFromPusher()
 * Determines whether the ticket list should be updated based on the received pusher event parameters.
 *
 * This method evaluates the parameters and applies the following logic to determine if the ticket list
 * should be updated:
 * - If the project ID of the new ticket does not match the provided project ID, return false.
 * - If the resolve status of the new ticket is not 0, return false.
 * - If the queue type is 'all', return true if the new ticket is assigned to an agent or a group.
 * - If the queue type is 'self', return true if the new ticket is assigned to the current user.
 * - If the queue type is 'unassigned', return true if the new ticket is not assigned to any agent or group.
 * - Otherwise, return false.
 *
 * @param {Object} parameters - The pusher event parameters.
 * @param {number} parameters.userId - The ID of the current user.
 * @param {number} parameters.projectId - The ID of the project.
 * @param {string} parameters.queueType - The type of the ticket queue.
 * @param {number} parameters.resolveStatus - The resolve status of the new ticket.
 * @param {TicketInterface} parameters.newticketData - The data of the new ticket.
 * @returns {boolean} True if the ticket list should be updated, false otherwise.
 */
//Note: Any modification of this function is prohibited unless approved by senior engineers.
export const shouldUpdateTicketListFromPusher = (parameters: {
  userId: number;
  projectId: number;
  queueType: string;
  resolveStatus: number;
  newticketData: TicketInterface;
}) => {
  let assignedAgent = parameters.newticketData?.assigned_agent || 0;
  let assignedGroup = parameters.newticketData?.assigned_group || 0;

  if (parameters.projectId !== parameters.newticketData?.project_id)
    return false;
  if (parameters.resolveStatus !== 0) return false;
  if (parameters.queueType === 'all')
    return Number(assignedAgent) > 0 || Number(assignedGroup) > 0;
  if (parameters.queueType === 'self')
    return assignedAgent === parameters.userId;
  if (parameters.queueType === 'unassigned')
    return assignedAgent === 0 && assignedGroup === 0;
  return false;
};
/**
Determines whether a secondary filter matches the given criteria.
@param {boolean} isReplied - Indicates whether a message is replied to.
@param {string} secondaryQueueType - The type of secondary queue filter.
@returns {boolean} - True if the secondary filter matches the criteria, otherwise false.
*/
export const checkIsSecondaryFilterMatch = (
  isReplied: boolean,
  secondaryQueueType: string
) => {
  switch (secondaryQueueType) {
    case 'replied':
      return isReplied;
    case 'unreplied':
      return !isReplied;
    case 'all':
    default:
      return true;
  }
};

/**
 * Check if the new ticket data matches the primary filters specified in the filter data.
 *
 * @param {boolean} hasFilterApplied - Indicates if any filter is applied.
 * @param {TicketFilterInterface} filterData - Filter data containing channels, tags, agents, and groups.
 * @param {TicketInterface} newTicketData - New ticket data to be checked against the filters.
 * @returns {boolean} - Returns true if the new ticket data matches the primary filters, otherwise false.
 */
export const checkIsPrimaryFilterMatched = (
  hasFilterApplied: boolean,
  filterData: TicketFilterInterface,
  newTicketData: TicketInterface
) => {
  // Check if any channel in the filter data matches the customer's platform ID of the new ticket
  if (hasFilterApplied) {
    const isAnyChannelMatched = filterData.channels.includes(
      String(newTicketData?.customer_platform_id)
    );

    // Check if any tag in the filter data matches any tag in the new ticket
    const isAnyTagMathced = filterData.tags.some((filterTag: any) =>
      newTicketData.tag_ids.some(
        (newTicketTag: any) => String(filterTag.id) === String(newTicketTag.id)
      )
    );

    // Check if any agent in the filter data matches the assigned agent of the new ticket
    const isAnyAgentMatched = filterData.agents.includes(
      newTicketData?.assigned_agent
    );
    // Check if any group in the filter data matches the assigned group of the new ticket
    const isAnyGroupMatched = filterData.groups.includes(
      newTicketData?.assigned_group
    );

    let endDateTimestamp = convertDateStringToTimeStamp(filterData.endDate);

    const isTicketCreatedDateGreaterThanEndDate =
      Number(newTicketData?.created_at) > endDateTimestamp;

    if (!hasFilterApplied) return true;
    if (filterData.channels.length !== 0 && !isAnyChannelMatched) return false;
    if (filterData.tags.length !== 0 && !isAnyTagMathced) return false;
    if (filterData.agents.length !== 0 && !isAnyAgentMatched) return false;
    if (filterData.groups.length !== 0 && !isAnyGroupMatched) return false;
    if (
      filterData.endDate.length !== 0 &&
      isTicketCreatedDateGreaterThanEndDate
    )
      return false;
  }

  return true;
};

export const getTicketCountStringForWindowTabTitle = (
  totalLocal: number | string
) => {
  return (document.title = `You have ${totalLocal} Pending Tickets`);
};

export const filterAvailableWhatsappBSPChannels = (
  project: SelectedTeamInterface
) => {
  let list = project?.platforms.filter(
    (item: any) => item.platform_type === 'whatsapp_bsp' && item.is_connected
  );
  return list;
};

export const filterAvailableWhatsappBSPAndEmailChannels = (
  integratedChannelList: PlatformInterface[],
  isPhoneNumber: boolean,
  isEmail: boolean
) => {
  if (!integratedChannelList || !integratedChannelList) return [];

  const platforms = integratedChannelList;

  return platforms.filter((item: any) => {
    if (isPhoneNumber) {
      return item.platform_type === 'whatsapp_bsp' && item.is_connected;
    } else if (isEmail) {
      return emailChannels.includes(item.platform_type) && item.is_connected;
    }
    return false;
  });
};

export const getWhatsappAndEmailChannelCount = (
  project: SelectedTeamInterface
) => {
  let emailChannelCount = 0;
  let whatsAppChannelCount = 0;

  if (!project || !project.platforms)
    return { emailChannelCount, whatsAppChannelCount };

  const platforms = project.platforms;

  emailChannelCount = platforms.filter(
    (item: any) =>
      emailChannels.includes(item.platform_type) && item.is_connected
  ).length;

  whatsAppChannelCount = platforms.filter(
    (item: any) => item.platform_type === 'whatsapp_bsp' && item.is_connected
  ).length;

  return { emailChannelCount, whatsAppChannelCount };
};

export const checkValidity = (phoneNumber: any) => {
  let expression = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,7}$/;
  let regex = new RegExp(expression);
  let validity = regex.test(phoneNumber);
  return validity;
};

export const validateMobileNumberWithoutCountryCode = (
  mobileNumber: string
) => {
  const mobileNumberPattern = /^\d{7,15}$/;
  return mobileNumberPattern.test(mobileNumber);
};

export const validateCountryCode = (countryCode: string) => {
  const countryCodePattern = /^\+\d{1,3}$/;
  return countryCodePattern.test(countryCode);
};

export const handleCustomerNameFormat = (name: string) => {
  if (name.length > 30) {
    name = name.substring(0, 30) + '...';
  }
  return name;
};

/**
 * Determines the text direction based on the input text.
 *
 * This function checks if the input text contains any Arabic characters. If Arabic characters
 * are found, it returns 'rtl' (right-to-left) to indicate that the text direction should be
 * right-to-left. Otherwise, it returns 'ltr' (left-to-right) to indicate that the text direction
 *
 * @param {string} text - The text for which to determine the direction.
 * @returns {'rtl' | 'ltr'} - 'rtl' for right-to-left text direction if Arabic characters are found,
 *                            otherwise 'ltr' for left-to-right text direction.
 */
export const getLangDirection = (text: string) => {
  const arabicPattern = /[\u0600-\u06FF]/;
  return arabicPattern.test(text) ? 'rtl' : 'ltr';
};

export const filterApprovedTemplates = (
  whatsappTemplates: WhatsappTemplate[]
) => {
  return (
    !!whatsappTemplates &&
    whatsappTemplates.filter(
      (item: WhatsappTemplate) => item.status === 'approved'
    )
  );
};

export const filterWhatsappTemplates = (
  whatsappTemplates: WhatsappTemplate[]
) => {
  return filterApprovedTemplates(whatsappTemplates).filter(
    (item: WhatsappTemplate) =>
      item.category !== 'AUTHENTICATION' && item?.template_type !== 'CAROUSEL'
  );
};

export const highlightAttributeFromText = (text: string) => {
  let textSplit = text.split(/[.\s]/);
  return textSplit.map((item: any, index: number) => {
    if (item.match(/{{[^{}]*}}/)) {
      let attribute = item.slice(item.indexOf('{') + 2, item.indexOf('}'));
      let textBefore = item.slice(0, item.indexOf('{'));
      let textAfter = item.slice(item.indexOf('}') + 2);
      return `${textBefore}<span id="${attribute + '_' + index}"
      class='px-1 py-1 bg-red-100 rounded-md text-red-800'>
      ${attribute}</span>${textAfter} `;
    } else {
      return `${item} `;
    }
  });
};

export function transformTextStyle(text: string) {
  try {
    if (typeof text !== 'string') {
      throw new TypeError('Input must be a string');
    }

    const patterns = [
      { regex: /(?:\s|\n)\*+([^\s*][^\n*]*)\*+/g, tag: 'b' },
      { regex: /(?:\s|\n)_+([^\s_][^\n_]*)_+/g, tag: 'i' },
      { regex: /(?:\s|\n)~+([^\s~][^\n~]*)~+/g, tag: 's' },
    ];

    return patterns.reduce((result, { regex, tag }) => {
      return result.replace(regex, `<${tag}>$1</${tag}>`);
    }, text);
  } catch (error: any) {
    console.error('Error in transformTextStyle:', error.message ?? '');
    return text;
  }
}

export const calculateDiscount = (
  discountType: string,
  totalCost: number,
  discountAmount: number
): number => {
  if (discountType === 'PERCENTAGE') {
    return (totalCost * discountAmount) / 100;
  }
  if (discountType === 'FIXED_AMOUNT') {
    return discountAmount;
  }
  return 0;
};

export const downloadMediaAttachment = async (
  selectedMedia: string,
  mediaType: string,
  attachmentData: any = ''
) => {
  try {
    if (mediaType === 'video') {
      let blob = await fetch(selectedMedia, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
      }).then((res) => res.blob());
      let url = URL.createObjectURL(blob);
      let element = document.createElement('a');
      element.href = url;
      element.download = 'video.mp4';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    } else {
      const imageData = attachmentData.data.imageData;
      const byteCharacters = atob(imageData);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/octet-stream' });

      const url = URL.createObjectURL(blob);
      const element = document.createElement('a');
      element.href = url;
      element.download = 'image.png';
      element.click();
      URL.revokeObjectURL(url);
    }
  } catch (err) {
    console.log('Unable to download media', err);
  }
};

export const getCurrentOrderFormInformationValue = (
  orderFormInformation: {
    [key: string]: OrderFormInformationType;
  },
  customerId: number
) => {
  for (const key in orderFormInformation) {
    if (parseInt(key) === customerId) {
      return orderFormInformation[key];
    }
  }
  return null;
};

export const getCartSubtotal = (cart: IEcommerceProductData[]) => {
  let subtotal = 0;
  if (!!cart) {
    cart.forEach((product) => {
      subtotal += product.total_cost;
    });

    return subtotal.toFixed(2);
  } else return 0;
};

export const isFilterAppliedWithOutPrivateView = (
  queueType: string,
  ticketFilterData: TicketFilterInterface
) => {
  return (
    queueType !== 'private' &&
    JSON.stringify(ticketFilterData) !==
      JSON.stringify(ticketFilterDefaultValue)
  );
};

// Determines if the URL is image type or not
export const isImageTypeUrl = (url: string) => {
  return new RegExp(/\.(jpeg|jpg|gif|png)$/).test(url);
};

export const sortOrdersFirstByStatus = (
  customerOrderList: ICustomerOrderProperty[]
) => {
  const sortedOrderList = [...customerOrderList];
  sortedOrderList.sort((a, b) => {
    if (a.status === 'OPEN' && b.status !== 'OPEN') {
      return -1;
    } else if (a.status !== 'OPEN' && b.status === 'OPEN') {
      return 1;
    } else {
      return 0;
    }
  });
  return sortedOrderList;
};

export const getStringFromObject = (
  obj: IAddress,
  fields: Array<string> = [],
  fullObject: boolean = false
) => {
  let objectToString = '';

  if (fullObject) {
    objectToString =
      `${obj?.first_name} ${obj?.last_name}` +
      `${obj?.email && ', ' + obj?.email}` +
      `${obj?.phone && ', ' + obj?.phone}` +
      `${obj?.address_one && ', ' + obj?.address_one}` +
      `${obj?.address_two && ', ' + obj?.address_two}` +
      `${obj?.city && ', ' + obj?.city}` +
      `${obj?.state && ', ' + obj?.state}` +
      `${obj?.postcode && ', ' + obj?.postcode}`;
  } else {
    for (let key of fields) {
      if (obj[key]) {
        objectToString += !!objectToString ? ', ' + obj[key] : obj[key];
      }
    }
  }

  return !!objectToString ? objectToString : 'N/A';
};

export const hasDynamicUrlButtonType = (selectedTemplate: WhatsappTemplate) => {
  return (
    selectedTemplate?.buttons &&
    selectedTemplate?.buttons?.length === 1 &&
    selectedTemplate?.buttons[0].type === 'url' &&
    typeof selectedTemplate?.buttons[0]?.value === 'string' &&
    !!selectedTemplate?.buttons[0]?.value.match(/{{.*}}/)
  );
};

const ecommerceProductsForUI = (products: IEcommerceProductData[]) => {
  let modifiedList: IEcommerceProductDataForUI[] = [];
  let map = new Map();
  for (let i = 0; i < products.length; i++) {
    if (map.has(products[i].product_id)) {
      if (products[i]?.has_variations)
        map.get(products[i].product_id)?.variations.push({
          variant_id: products[i].variant_id,
          variant_attributes: products[i].variant_attributes,
          variant_attributes_string: products[i].variant_attributes_string,
          quantity: products[i].quantity,
          unit_price: products[i].unit_price,
        });
    } else {
      map.set(products[i].product_id, {
        ...products[i],
        variations: !products[i].has_variations
          ? []
          : [
              {
                variant_id: products[i].variant_id,
                variant_attributes: products[i].variant_attributes,
                variant_attributes_string:
                  products[i].variant_attributes_string,
                quantity: products[i].quantity,
                unit_price: products[i].unit_price,
              },
            ],
      });
    }
  }

  modifiedList = Array.from(map.values());
  return [...modifiedList];
};

const ecommerceProductsForApi = (
  modifiedList: IEcommerceProductDataForUI[]
) => {
  let products: IEcommerceProductData[] = [];
  modifiedList.forEach((data: IEcommerceProductDataForUI) => {
    if (!data.has_variations) {
      if (data.hasOwnProperty('variations')) delete data.variations;
      products.push({ ...data });
    } else {
      data?.variations?.forEach((variant) => {
        let updatedData = { ...data };
        updatedData.variant_attributes = [...variant.variant_attributes];
        updatedData.variant_attributes_string =
          variant.variant_attributes_string;
        updatedData.variant_id = variant.variant_id;
        updatedData.quantity = variant.quantity;
        updatedData.unit_price = variant.unit_price;

        delete updatedData.variations;
        products.push({ ...updatedData });
      });
    }
  });

  return [...products];
};

export const EcommerceProductsFunctions = {
  ecommerceProductsForUI,
  ecommerceProductsForApi,
};

// to render the json
export const customFormatJson = (jsonString: string) => {
  try {
    const jsonObj = JSON.parse(jsonString);
    const formattedJsonString = formatJsonObject(jsonObj);
    return formattedJsonString;
  } catch (error) {
    console.error('Invalid JSON:', error);
    return jsonString; // Return the original string if parsing fails
  }
};

const formatJsonObject = (obj: any, depth = 0) => {
  const indent = ' '.repeat(depth * 2);
  const entries = Object.entries(obj);

  if (entries.length === 0) {
    return '{}';
  }

  const formattedEntries: any[] = entries.map(([key, value]) => {
    const formattedValue =
      typeof value === 'object'
        ? formatJsonObject(value, depth + 1)
        : JSON.stringify(value);
    return `${indent}  "${key}": ${formattedValue}`;
  });

  return `{\n${formattedEntries.join(',\n')}\n${' '.repeat(
    depth > 0 ? (depth - 1) * 2 : depth
  )}}`;
};

/**
 * @param fieldsData
 * @param localSelectedDatalabEntry
 * @returns modified list from fieldsData
 * mapping the group fields with child fields in inbox datalab and add value to fields
 */
export const normalizeInboxDatalabFields = (
  fieldsData: any[],
  localSelectedDatalabEntry: any,
  customerInformation: CustomerInfoInterface
) => {
  let updatedField = cloneArray(fieldsData);
  let childFieldMap = mapChildFields(fieldsData, customerInformation);

  updatedField = filterOutGroupFields(updatedField);

  const normalizeFields = updateFieldValues(
    updatedField,
    childFieldMap,
    customerInformation
  );

  if (!!localSelectedDatalabEntry) {
    updateSelectedEntryValues(normalizeFields, localSelectedDatalabEntry);
  }

  return [...normalizeFields];
};

const replacePlaceholders = (template, data): string => {
  const regex = /{{(.*?)}}/g;

  const replacedString = template.replace(regex, (match, attributeName) => {
    const attributeNames = attributeName.split('.');

    const attributeValue =
      data[attributeNames[attributeNames.length - 1].trim()];
    return attributeValue !== undefined ? attributeValue : '';
  });

  return replacedString || '';
};

const cloneArray = (arr: any[]) => [...arr];

const mapChildFields = (
  fieldsData: any[],
  customerInformation: CustomerInfoInterface
) => {
  let childFieldMap = new Map();
  fieldsData.forEach((field) => {
    if (!!field?.parent_id) {
      if (!childFieldMap.has(field?.parent_id)) {
        childFieldMap.set(field?.parent_id, [[]]);
      }
      let arr = childFieldMap.get(field?.parent_id);
      const generatedValue = replacePlaceholders(
        field?.prefilled_customer_attribute,
        customerInformation
      );
      arr[0].push({ ...field, value: generatedValue, error_message: '' });
      childFieldMap.set(field?.parent_id, arr);
    }
  });
  return childFieldMap;
};

const filterOutGroupFields = (fieldsData: any[]) =>
  fieldsData.filter((data) => !data?.parent_id);

const updateFieldValues = (
  updatedField: any[],
  childFieldMap: Map<any, any>,
  customerinformation: CustomerInfoInterface
) =>
  updatedField.map((obj) => ({
    ...obj,
    value:
      obj?.type === 'group'
        ? childFieldMap.get(obj?.id)
        : replacePlaceholders(
            obj?.prefilled_customer_attribute,
            customerinformation
          ),
    error_message: '',
  }));

const updateSelectedEntryValues = (
  updatedField: any[],
  localSelectedDatalabEntry: any
) => {
  for (const key in localSelectedDatalabEntry) {
    const value = localSelectedDatalabEntry[key];
    const matchingElement = updatedField.find(
      (element) => element.id === Number(key)
    );

    if (matchingElement) {
      updateElementValue(matchingElement, value);
    }
  }
};

const updateElementValue = (element: any, value: any) => {
  if (element.type !== 'group') {
    element.value = value;
  } else {
    updateGroupElementValues(element, value);
  }
};

const updateGroupElementValues = (
  groupElement: any,
  groupElementValue: any
) => {
  const groupElementCopy = { ...groupElement };
  const groupElementValues = groupElementValue[0];

  for (const childElementKey in groupElementValues) {
    const childElementValue = groupElementValues[childElementKey];
    const childElement = groupElement.value[0].find(
      (element: any) => element.id === Number(childElementKey)
    );

    if (!!childElement) {
      childElement.value = childElementValue;
    }
  }

  groupElement.value = groupElementCopy.value;
};

export const truncateString = (str: string | number, maxLength: number) => {
  if (typeof str === 'number') {
    str = str.toString();
  }
  return str.length > maxLength ? `${str.slice(0, maxLength)}...` : str;
};

export const convertHttpToHttps = (url: string | null | string[]) => {
  // If the input is a string
  if (typeof url === 'string') {
    // Check if the URL starts with "http://" (case insensitive)
    if (url.toLowerCase().startsWith('http://')) {
      // Replace "http://" with "https://"
      return url.replace('http://', 'https://');
    }
    // If the URL is already HTTPS or doesn't start with "http://", return it as is
    return url;
  }
  // If the input is an array of URLs
  else if (Array.isArray(url)) {
    // Map over the array and apply the same logic to each URL
    if (
      typeof url[0] === 'string' &&
      url[0].toLowerCase().startsWith('http://')
    ) {
      return url[0].replace('http://', 'https://');
    }
  }
  // If the input is null or not a string or array, return null
  return null;
};

export const extractDatalabLinkTag = (
  str: string
): null | { key: string; value: any } => {
  const regex = /<([^>]+)>/g;
  let matches = str.match(regex);

  if (!matches) {
    return null;
  }

  let extractedValues = matches.map((match) => {
    return match.substring(1, match.length - 1);
  });

  let result = { key: '', value: '' };
  extractedValues.forEach((value) => {
    let [key, val] = value.split(':');
    result = { key, value: val };
  });

  return result;
};

export const getReplacedLink = (fields: IDataLabField[], link: string) => {
  const extractedObject = extractDatalabLinkTag(link);
  if (!extractedObject) {
    return link;
  }

  // Find the matching data from fields based on extracted key and value
  const matchingField = fields.find((field) => {
    //@ts-ignore
    let fieldValue = field[`${extractedObject.key}`];
    if (!isNaN(extractedObject.value))
      extractedObject.value = Number(extractedObject.value);
    return fieldValue !== undefined && fieldValue === extractedObject.value;
  });

  if (!matchingField) {
    return link.replace(
      `<${extractedObject?.key}:${extractedObject.value}>`,
      'untitled'
    );
  } else {
    return link.replace(
      `<${extractedObject?.key}:${extractedObject.value}>`,
      matchingField?.value
    );
  }
};

export const analyzeSavedReplyPlatformRestrictions = (
  platformType: TPlatforms,
  savedReplyAttachments: ISavedReplyAttachemnt[]
): {
  hasPlatformRestriction: boolean;
  warningMessage: string | false | null;
  allowedAttachments: ISavedReplyAttachemnt[];
} => {
  if (!Array.isArray(savedReplyAttachments)) {
    savedReplyAttachments = [];
  }
  const restrictedType = SAVED_REPLY_PLATFORM_RESTRICTIONS[platformType];
  const hasPlatformRestriction =
    Array.isArray(restrictedType) && !!restrictedType.length;
  if (!hasPlatformRestriction) {
    return {
      hasPlatformRestriction,
      warningMessage: '',
      allowedAttachments: savedReplyAttachments,
    };
  }
  const allowedAttachments = savedReplyAttachments?.filter(
    (a) => !restrictedType.includes(a?.type)
  );
  const hasInvalidAttachment =
    allowedAttachments.length !== savedReplyAttachments.length;
  const warningMessage =
    hasInvalidAttachment && getSavedReplyWarningByPlatform(platformType);
  return {
    hasPlatformRestriction,
    warningMessage,
    allowedAttachments,
  };
};
