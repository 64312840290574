import axios from 'utilities/httpClient';
import config from 'utilities/config';

const initialState = {
  savedReplies: [],
  currentSavedRepliesAction: 'CREATE',
};

export const settingsV2 = {
  state: {
    ...initialState,
  },
  reducers: {
    updateStateData(state, payload) {
      return { ...state, [payload.key]: payload.value };
    },
    updateSavedReplies(state, payload) {
      return { ...state, savedReplies: payload };
    },
  },
  effects: (dispatch) => ({
    async fetchSavedReplies(teamId) {
      try {
        const res = await axios.get(config.settings.cannedResponse(teamId));
        if (res.status === 200) {
          dispatch.settingsV2.updateSavedReplies(res.data.dataSource);
        }
        return true;
      } catch (err) {
        return false;
      }
    },
    async createNewSavedReply(payload, rootState) {
      try {
        const res = await axios.post(
          config.settings.cannedResponse(payload.projectId),
          payload.data
        );
        // insert newly created saved reply to state
        dispatch.settingsV2.updateSavedReplies([
          res?.data?.dataSource,
          ...rootState?.settingsV2?.savedReplies,
        ]);
        return {
          success: true,
          data: res.data,
        };
      } catch (err) {
        return {
          success: false,
          data: err.response.data,
        };
      }
    },
    async deleteSavedReplies(payload, rootState) {
      const selectedSavedReplies = payload?.selectedSavedReplies || [];
      try {
        const res = await axios.delete(
          config.settings.bulkDeleteCannedResponse(payload.projectId),
          {
            data: {
              ids: selectedSavedReplies,
            },
          }
        );
        // remove saved reply from state
        dispatch.settingsV2.updateSavedReplies([
          ...rootState?.settingsV2?.savedReplies?.filter(
            (r) => !selectedSavedReplies.includes(r.id)
          ),
        ]);

        return {
          success: true,
          data: res.data,
        };
      } catch (err) {
        return {
          success: false,
          data: err.response.data,
        };
      }
    },
    async editSavedReply(payload, rootState) {
      try {
        const res = await axios.patch(
          config.settings.editCannedResponse(
            payload.projectId,
            payload.cannedResponseId
          ),
          payload.data
        );
        if (res.data.success) {
          dispatch.settingsV2.updateSavedReplies([
            ...rootState?.settingsV2?.savedReplies?.map((r) =>
              r.id === payload.cannedResponseId ? res.data.dataSource : r
            ),
          ]);

          return {
            success: true,
            data: res.data,
          };
        }
      } catch (err) {
        return {
          success: false,
          data: err.response.data,
        };
      }
    },
  }),
};

export default settingsV2;
