import { useSelector, useDispatch } from 'react-redux';

const useMiddleBarHook = () => {
  const dispatch = useDispatch();
  const authData = useSelector((state: any) => state.auth);
  const inboxData = useSelector((state: any) => state.inbox);
  const dashboardData = useSelector((state: any) => state.dashboard);

  const { id } = authData;
  const { selectedQueueType, selectedTicket } = inboxData;
  const { agentAccess, selectedProject } = dashboardData;

  const isUserRestrictedToAssignOtherAgentOrGroup = () => {
    const restrictedTeams = { OhSoGo: 12275, 'Clone of MTB': 375 };
    const restrictedRoles = [1034];
    const restrictedQueue = ['unassigned'];

    if (
      Object.values(restrictedTeams).includes(selectedProject?.id) &&
      restrictedQueue.includes(selectedQueueType)
    ) {
      const userAccess = agentAccess.find((accessObj: any) => {
        return accessObj?.admin?.id === id;
      });
      if (userAccess) {
        return restrictedRoles.includes(userAccess.role.id);
      }
    }
    return false;
  };

  const teamOhsogoCustomModificationObject = {
    isUserRestrictedToAssignOtherAgentOrGroup:
      isUserRestrictedToAssignOtherAgentOrGroup(),
  };

  const handleTicketPriority = async (
    ticketId: string | number,
    priority: string | number
  ) => {
    let res = await dispatch.inbox.setTicketPriority({ ticketId, priority });
    if (res) {
      let updatedTicket = {
        ...selectedTicket,
        priority: res.dataSource.priority,
        priority_str: res.dataSource.priority_str,
      };
      dispatch.inbox.updateSelectedTicket(updatedTicket);
    }
  };

  return {
    selectedTicket,
    handleTicketPriority,
    teamOhsogoCustomModificationObject,
  };
};

export default useMiddleBarHook;
