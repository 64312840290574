import { toaster } from 'evergreen-ui';
import cloneDeep from 'lodash/cloneDeep';
import config from '../../../utilities/config';
import AXIOS from '../../../utilities/httpClient';
import LivechatDefaultData from './LiveChatDefaultData';
import WhatsappTemplateDefaultData from './WhatsappTemplateDefaultData';

const initialState = {
  selectedPlatformsList: [],
  availablePlatformsList: ['E-commerce', 'Channel', 'NLP'], //E-commerce, Others will be added soon
  integratedNlpList: [],
  integratedChannelList: [],
  integratedOthersList: [],
  integratedEcommerceList: null,
  chatbotSettings: null,
  businessHourTotalData: null,
  migratedWebchatId: null,
  liveChatData: { ...LivechatDefaultData },
  emailCredentialsToUpdate: '',
  agentList: [],
  agentGroups: [],
  whatsappTemplateData: { ...WhatsappTemplateDefaultData },
  ecommerceChannelPreferencesList: [],
};

export const integration = {
  state: {
    ...initialState,
  },
  reducers: {
    updateStateData(rootState, payload) {
      return { ...rootState, [payload.key]: payload.value };
    },

    updateNLPInfo(rootState, payload) {
      return {
        ...rootState,
        nlpInfo: { ...rootState.nlpInfo, [payload.key]: payload.value },
      };
    },

    updateIntegratedNLPList(rootState, payload) {
      return { ...rootState, integratedNlpList: payload };
    },

    updateIntegratedOthersList(rootState, payload) {
      return { ...rootState, integratedOthersList: payload };
    },

    updateIntegratedEcommerceList(rootState, payload) {
      return { ...rootState, integratedEcommerceList: payload };
    },

    updateEcommerceChannelPreferencesList(rootState, payload) {
      return { ...rootState, ecommerceChannelPreferencesList: payload };
    },

    updateSingleIntegratedNlp(rootState, payload) {
      let cloneData = cloneDeep(rootState.integratedNlpList);
      cloneData.forEach((element, index) => {
        if (element.id === payload.id) {
          cloneData[index] = payload;
        }
      });
      return { ...rootState, integratedNlpList: cloneData };
    },

    removeSingleIntegratedNlp(rootState, payload) {
      let cloneData = cloneDeep(rootState.integratedNlpList);
      cloneData.forEach((element, index) => {
        if (element.id === payload) {
          cloneData = [
            ...cloneData.slice(0, index),
            ...cloneData.slice(index + 1),
          ];
        }
      });
      return { ...rootState, integratedNlpList: cloneData };
    },

    updateIntegratedChannelList(rootState, payload) {
      return { ...rootState, integratedChannelList: payload };
    },

    updateSingleChannelOnList(rootState, payload) {
      let cloneData = cloneDeep(rootState.integratedChannelList);
      if (!!payload) {
        cloneData.forEach((element, index) => {
          if (element.id === payload.id) {
            cloneData[index] = { ...payload };
          }
        });
      }
      return { ...rootState, integratedChannelList: cloneData };
    },

    updateSingleOtherChannelOnList(rootState, payload) {
      let cloneData = cloneDeep(rootState.integratedOthersList);
      if (!!payload) {
        cloneData.forEach((element, index) => {
          if (element.id === payload.id) {
            cloneData[index] = payload;
          }
        });
      }
      return { ...rootState, integratedOthersList: cloneData };
    },

    updateLiveChatBrandData(rootState, payload) {
      let oldData = cloneDeep(rootState);
      oldData.liveChatData[payload.key] = payload.value;
      return { ...rootState, liveChatData: oldData.liveChatData };
    },

    updateNestedLiveChatData(rootState, payload) {
      let oldData = cloneDeep(rootState);
      if (!oldData.liveChatData['livechat_data'])
        oldData.liveChatData['livechat_data'] = {};

      oldData.liveChatData.livechat_data[payload.key] = payload.value;
      return { ...rootState, liveChatData: oldData.liveChatData };
    },

    // removeSingleChannelfromList(rootState, payload) {
    //   let cloneData = cloneDeep(rootState.integratedChannelList);
    //   const updatedList = cloneData.filter(
    //     (element) => element.id !== payload.id
    //   );
    //   return { ...rootState, integratedChannelList: updatedList };
    // },
    clearState() {
      return { ...initialState };
    },
    updateChatbotSettings(rootState, payload) {
      return { ...rootState, chatbotSettings: payload };
    },
    updateWhatsappTemplateData(rootState, payload) {
      return {
        ...rootState,
        whatsappTemplateData: payload,
      };
    },
    updateWhatsappTemplateDataField(rootState, payload) {
      let oldData = cloneDeep(rootState.whatsappTemplateData);
      oldData[payload.key] = payload.value;
      return {
        ...rootState,
        whatsappTemplateData: oldData,
      };
    },
    addWhatsappTemplateButton(rootState, payload) {
      /*
        payload : { value : {id : 1,type : "sequence", value : 'ads', title : "btn-1"}}
      */
      let oldData = cloneDeep(rootState);
      oldData.whatsappTemplateData.buttons = [
        ...oldData.whatsappTemplateData.buttons,
        ...payload,
      ];
      return {
        ...rootState,
        whatsappTemplateData: oldData.whatsappTemplateData,
      };
    },
    updateWhatsappTemplateButtonData(rootState, payload) {
      //  payload : { value : {id : 1,type : "sequence", value : 'ftw', title : "btn-1"}}
      let oldData = cloneDeep(rootState.whatsappTemplateData);
      oldData.buttons = oldData?.buttons.map((button) => {
        return button.id === payload.id ? { ...button, ...payload } : button;
      });
      return {
        ...rootState,
        whatsappTemplateData: oldData,
      };
    },
    updateEcommerceData(rootState, updatedData) {
      if (
        !rootState.integratedEcommerceList ||
        rootState.integratedEcommerceList?.length < 1
      )
        return;
      const existingList = cloneDeep(rootState.integratedEcommerceList);
      const ecommerceList = existingList.map((data) => {
        if (data?.alice_store_id === updatedData?.alice_store_id)
          return { ...updatedData };
        else return data;
      });

      return { ...rootState, integratedEcommerceList: ecommerceList };
    },
  },

  effects: (dispatch) => ({
    async fetchSequence(platformId) {
      try {
        const res = await AXIOS.get(config.builder.sequence(platformId));
        if (res.data.success) {
          return res.data.dataSource;
        }
      } catch (err) {
        if (err.response.status === 500) {
          toaster.danger(
            'Something went wrong while fetching platform sequence. Try again later.'
          );
        } else {
          toaster.danger(err?.response?.data?.error);
        }
        return [];
      }
    },

    async fetchUserAccountInfo() {
      try {
        const res = await AXIOS.get(config.auth.info());
        if (res.status === 200) {
          return res.data.dataSource['has_viewed_integrations'];
        }
      } catch (err) {
        if (err?.response?.status === 500) {
          toaster.danger(
            'Something went wrong while fetching account info. Try again later.'
          );
        } else {
          toaster.danger(err?.response?.data?.error);
        }
      }
    },

    async fetchFacebookPages(payload) {
      /*
        payload = {
          id: num // fb userID
          token: string //temp accessToken
        }
       */
      try {
        const res = await AXIOS.get(
          config.channel.facebookPages(payload.id, payload.token, payload.type)
        );
        return res.data.dataSource;
      } catch (err) {
        if (err.response.status === 500) {
          toaster.danger(
            'Something went wrong while fetching facebook pages. Try again later.'
          );
        } else {
          toaster.danger(err?.response?.data?.error);
        }
        return false;
      }
    },

    async fetchInstagramPages(payload) {
      /*
        payload = {
          id: string // fb userID
          token: string //temp accessToken
          projectId: num
        }
       */
      try {
        const res = await AXIOS.get(
          config.channel.instagramPage(
            payload.id,
            payload.token,
            payload.type,
            payload.projectId
          )
        );
        return res.data.dataSource;
      } catch (err) {
        if (err.response.status === 500) {
          toaster.danger(
            'Something went wrong while fetching instagram pages. Try again later.'
          );
        } else {
          toaster.danger(err?.response?.data?.error);
        }
      }
    },

    async updateUserAccountInfo(payload) {
      try {
        const res = await AXIOS.patch(config.auth.info(), payload);
        if (res.status === 200) {
          return res;
        }
      } catch (err) {
        if (err.response.status === 500) {
          toaster.danger(
            'Something went wrong while fetching user account info. Try again later.'
          );
        } else {
          toaster.danger(err?.response?.data?.error);
        }
      }
    },

    async getIntegratedNlpList(teamId) {
      try {
        const res = await AXIOS.get(
          config.integration.getIntegratedNlpList(teamId)
        );
        if (res.status === 200) {
          dispatch.integration.updateIntegratedNLPList(res.data.dataSource);
          if (res?.data?.dataSource) return res.data.dataSource.length !== 0;
        }
      } catch (err) {
        if (err.response.status === 500) {
          toaster.danger(
            'Something went wrong while getting NLP list. Try again later.'
          );
        } else {
          toaster.danger(err?.response?.data?.error);
        }
        return false;
      }
    },
    async getIntegratedOthersList(teamId) {
      try {
        const res = await AXIOS.get(
          config.integration.getIntegratedOthersList(teamId)
        );
        if (res.status === 200) {
          dispatch.integration.updateIntegratedOthersList(res.data.dataSource);
          if (res?.data?.dataSource) return res.data.dataSource.length !== 0;
        }
      } catch (err) {
        if (err.response.status === 500) {
          toaster.danger(
            'Something went wrong while getting other integration list. Try again later.'
          );
        } else {
          toaster.danger(err?.response?.data?.error);
        }
        dispatch.integration.updateIntegratedOthersList([]);
        return false;
      }
    },

    async getSingleNlpData(payload) {
      try {
        const res = await AXIOS.get(
          config.integration.getSingleNlpData(
            payload.teamId,
            payload.integrationId
          )
        );
        if (res.status === 200) {
          return res.data.dataSource;
        }
      } catch (err) {
        if (err.response.status === 500) {
          toaster.danger(
            'Something went wrong while getting channel list. Try again later.'
          );
        } else {
          toaster.danger(err?.response?.data?.error);
        }
      }
    },

    async getSingleChannelData(payload) {
      try {
        const res = await AXIOS.get(
          config.integration.getSingleChannelData(
            payload.teamId,
            payload.channelId
          )
        );
        if (res.status === 200) {
          return res.data.dataSource;
        }
      } catch (err) {
        if (err.response.status === 500) {
          toaster.danger(
            'Something went wrong while getting channel list. Try again later.'
          );
        } else {
          toaster.danger(err?.response?.data?.error);
        }
      }
    },

    async getIntegratedChannelList(teamId) {
      try {
        const res = await AXIOS.get(
          config.integration.getIntegratedChannelList(teamId)
        );
        if (res.status === 200) {
          dispatch.integration.updateIntegratedChannelList(res.data.dataSource);
          dispatch.channel.updateChannelList(res.data.dataSource);
          dispatch.dashboard.updatePlatformList(res.data.dataSource);
          if (res?.data?.dataSource) return res.data.dataSource.length !== 0;
        }
      } catch (err) {
        if (err.response.status === 500) {
          toaster.danger(
            'Something went wrong while getting channel list. Try again later.'
          );
        } else {
          toaster.danger(err?.response?.data?.error);
        }
        dispatch.integration.updateIntegratedChannelList([]);
        return true;
      }
    },

    async createNlpIntegration(requestPayload) {
      /*
        requestPayload = {
          teamId: um // selectedProjectId
          payloadData: {
              name: string,
              app_id: string,
              confidence: number,
              access_token: string,
              provider: string,
              api_version: string,
          }
        }
       */
      try {
        const res = await AXIOS.post(
          config.integration.createNlpIntegration(requestPayload.teamId),
          requestPayload.payloadData
        );
        if (res.status === 200) {
          toaster.success('NLP channel successfully created');
          return res;
        }
      } catch (err) {
        return err.response;
      }
    },

    async modifyExistingNlpInfo(payload) {
      try {
        const res = await AXIOS.patch(
          config.integration.updateNlpIntegration(
            payload.projectId,
            payload.integrationId
          ),
          payload.body
        );
        if (res.status === 200) {
          dispatch.integration.updateSingleIntegratedNlp(res.data.dataSource);
          toaster.success('NLP updated successfully', { duration: 2 });
          return true;
        }
      } catch (err) {
        if (err.response.status === 500) {
          toaster.danger(
            'Something went wrong while updating the channel. Try again later.'
          );
        } else {
          toaster.danger(err?.response?.data?.error);
        }
        return false;
      }
    },

    async deleteNlpIntegration(payload) {
      try {
        const res = await AXIOS.delete(
          config.integration.deleteNlpIntegration(
            payload.teamId,
            payload.integrationId
          )
        );
        if (res.status === 200) {
          dispatch.integration.removeSingleIntegratedNlp(payload.integrationId);
          toaster.success('NLP deleted successfully', { duration: 2 });
          return true;
        }
      } catch (err) {
        if (err.response.status === 500) {
          toaster.danger(
            'Something went wrong while deleting the channel. Try again later.'
          );
        } else {
          toaster.danger(err?.response?.data?.error);
        }
        return false;
      }
    },

    async createChannelIntegrations(payload) {
      try {
        const res = await AXIOS.post(
          config.integration.createChannelIntegrations(
            payload.channelType,
            payload.teamId
          ),
          payload.data
        );

        if (res.status === 200) {
          !payload.hasOwnProperty('hideToaster') &&
            toaster.success(
              `Channel ${payload.data.title} has been successfully integrated.`
            );
          dispatch.channel.appendChannelList(res.data.dataSource);
          dispatch.dashboard.appendPlatformList(res.data.dataSource);
          return res;
        }
      } catch (err) {
        return err.response;
      }
    },

    async createOtherDynamicIntegration(payload) {
      try {
        const res = await AXIOS.post(
          config.integration.createOtherDynamicIntegration(payload.teamId),
          {
            ...payload.data,
            type: payload?.channelType,
            integration_id: payload.integrationId,
          }
        );

        if (res.status === 200) {
          !payload.hasOwnProperty('hideToaster') &&
            toaster.success(
              `Channel ${payload.data.name} has been successfully integrated.`
            );
          dispatch.channel.appendChannelList(res.data.dataSource);
          dispatch.dashboard.appendPlatformList(res.data.dataSource);
          return res;
        }
      } catch (err) {
        return err.response;
      }
    },

    async handleChannelPublishStatus(payload) {
      try {
        const res = await AXIOS.post(
          config.integration.handleChannelPublishStatus(
            payload.teamId,
            payload.channelId
          ),
          payload.data
        );
        if (res.status === 200) {
          dispatch.integration.updateSingleChannelOnList(res.data.dataSource);
          toaster.success(payload.alertMessage);
          return true;
        }
      } catch (err) {
        if (err.response.status === 500) {
          toaster.danger(
            'Something went wrong while integrating the channel. Try again later.'
          );
        } else {
          toaster.danger(err?.response?.data?.error);
        }
        return false;
      }
    },

    async disconnectChannel(payload) {
      try {
        const res = await AXIOS.post(
          config.integration.handleChannelDisconnect(
            payload.teamId,
            payload.channelId
          )
        );
        if (res.status === 200) {
          dispatch.integration.updateSingleChannelOnList(res.data.dataSource);
          toaster.success(payload.alertMessage);
          return true;
        }
      } catch (err) {
        if (err?.response?.status === 500) {
          toaster.danger(
            'Something went wrong while disconnecting the channel. Try again later.'
          );
        } else {
          toaster.danger(err?.response?.data?.error);
        }
        return false;
      }
    },

    async removeChannel(payload) {
      try {
        const res = await AXIOS.post(
          config.integration.handleChannelRemove(
            payload.teamId,
            payload.channelId
          )
        );
        if (res.status === 200) {
          await dispatch.integration.getIntegratedChannelList(payload.teamId);
          toaster.success('Channel successfully removed');
          return true;
        }
      } catch (err) {
        if (err.response.status === 500) {
          toaster.danger('Something went wrong while removing this channel');
        } else {
          toaster.danger(err?.response?.data?.error);
        }
        return false;
      }
    },

    async editChannel(payload) {
      try {
        const res = await AXIOS.patch(
          config.integration.handleChannelEdit(
            payload.teamId,
            payload.channelId
          ),
          payload.data
        );
        if (res.status === 200) {
          dispatch.integration.updateSingleChannelOnList(res.data.dataSource);
          !payload.hasOwnProperty('hideToaster') &&
            toaster.success(payload.alertMessage);
          return true;
        } else return false;
      } catch (err) {
        if (err.response.status === 500) {
          !payload.hasOwnProperty('hideToaster') &&
            toaster.danger('Something went wrong while updating this channel');
        } else {
          !payload.hasOwnProperty('hideToaster') &&
            toaster.danger(err?.response?.data?.error);
        }
        return false;
      }
    },

    async reconnectChannel(payload) {
      try {
        const res = await AXIOS.patch(
          config.integration.handleChannelReconnect(
            payload.teamId,
            payload.channelId
          ),
          payload.payloadData
        );
        if (res.status === 200) {
          dispatch.integration.updateSingleChannelOnList(res.data.dataSource);
          toaster.success(payload.alertMessage);
          return true;
        } else return false;
      } catch (err) {
        if (err.response.status === 500) {
          toaster.danger(
            'Something went wrong while reconnecting this channel'
          );
        } else {
          toaster.danger(err?.response?.data?.error);
        }
        return false;
      }
    },
    async instagramMessengerChannelConnectionValidation(payload) {
      try {
        const res = await AXIOS.post(
          config.integration.instagramMessengerChannelConnectionValidation(
            payload.channelType,
            payload.teamId
          ),
          payload.data
        );

        if (res.status === 200) {
          return res;
        }
      } catch (err) {
        return err.response;
      }
    },

    async createBetterDocsIntegration(payload) {
      try {
        const res = await AXIOS.post(
          config.integration.createBetterDocs(payload.teamId),
          payload.data
        );
        if (res.status === 200) {
          toaster.success(`Betterdocs has been successfully integrated.`);
          return res.data;
        }
      } catch (err) {
        return err.response.data;
      }
    },
    async editBetterDocs(payload) {
      try {
        const res = await AXIOS.patch(
          config.integration.handleBetterDocsEdit(
            payload.teamId,
            payload.betterdocsId
          ),
          payload.data
        );
        if (res.status === 200) {
          toaster.success(payload.alertMessage);
          return true;
        }
      } catch (err) {
        if (err.response.status === 500) {
          toaster.danger('Something went wrong while updating BetterDocs');
        } else {
          toaster.danger(err?.response?.data?.error);
        }
        return false;
      }
    },
    async removeBetterDocs(payload) {
      try {
        const res = await AXIOS.delete(
          config.integration.handleBetterDocsRemove(
            payload.teamId,
            payload.betterDocsId,
            payload.alertMessage
          )
        );
        if (res.status === 200) {
          await dispatch.integration.getIntegratedOthersList(payload.teamId);
          toaster.success(payload.alertMessage);
          return true;
        }
      } catch (err) {
        if (err.response.status === 500) {
          toaster.danger('Something went wrong while removing BetterDocs');
        } else {
          toaster.danger(err?.response?.data?.error);
        }
        return false;
      }
    },
    async handleRetriveBetterdocs(payload) {
      try {
        const res = await AXIOS.get(
          config.integration.handleRetriveBetterdocs(
            payload.teamId,
            payload.betterdocsId
          )
        );
        if (res.status === 200) {
          return res.data.dataSource;
        }
      } catch (err) {
        if (err.response.status === 500) {
          toaster.danger(
            'Something went wrong while getting betterdocs data. Try again later.'
          );
        } else {
          toaster.danger(err?.response?.data?.error);
        }
      }
    },
    async handleChatbotSettings(payload) {
      //  payload.data = {
      //     is_bot_enabled: boolean,
      //     agent_ids: number[],
      //     group_ids: number[],
      //     welcome_messages: string[],
      // }
      try {
        const res = await AXIOS.post(
          config.integration.chatbotSettings(payload.channelId),
          payload.data
        );
        if (res.status === 200) {
          dispatch.integration.fetchChatbotSettings(payload.channelId);
          return res.data;
        }
      } catch (err) {
        return err.response.data;
      }
    },
    async fetchChatbotSettings(channelId) {
      try {
        const res = await AXIOS.get(
          config.integration.chatbotSettings(channelId)
        );
        if (res.status === 200) {
          dispatch.integration.updateChatbotSettings(res.data.dataSource);
          return res.data.dataSource;
        }
      } catch (err) {
        return err.response.data;
      }
    },
    async fetchBusinessHours(teamId) {
      try {
        const res = await AXIOS.get(config.settings.businessHour(teamId));
        if (res.status === 200) {
          dispatch.businesshour.updateBusinessHour(res.data.dataSource);
        }
        return true;
      } catch (err) {
        return false;
      }
    },
    async createWhatsappMessageTemplate(payload) {
      // payload.data = {
      //   name: string,
      //   category: string,
      //   language: string,
      //   body: string,
      //   header: {
      //     type: string,
      //     value: string
      //   },
      //   buttons: []
      // }
      try {
        const res = await AXIOS.post(
          config.integration.createWhatsappTemplateMessage(payload.channelId),
          payload.data
        );
        if (res.status === 200) {
          payload.callback(res);
        }
      } catch (err) {
        payload.callback(err.response);
      }
    },
    async editWhatsappMessageTemplate(payload, state) {
      try {
        const response = await AXIOS.post(
          config.integration.editWhatsappTemplateMessage(
            payload.channelId,
            payload.templateId
          ),
          payload.data
        );

        if (response.status === 200) {
          const { channelData } = payload;
          if (
            channelData?.whatsapp_message_templates &&
            response.data.dataSource.data
          ) {
            channelData.whatsapp_message_templates =
              await channelData.whatsapp_message_templates.map((data) =>
                data.id === payload.templateId
                  ? response.data.dataSource.data
                  : data
              );
            const newList = await state.integration.integratedChannelList.map(
              (channel) =>
                channel.id === channelData.id ? { ...channelData } : channel
            );

            await dispatch.integration.updateIntegratedChannelList(
              cloneDeep(newList)
            );
          }
          payload.callback(response);
        }
      } catch (err) {
        payload.callback(err.response);
      }
    },

    async deleteWhatsappMessageTemplate(payload) {
      try {
        const res = await AXIOS.delete(
          config.integration.deleteWhatsappTemplateMessage(
            payload.channelId,
            payload.templateId
          )
        );
        if (res.status === 200) {
          return res;
        }
      } catch (err) {
        return err.response;
      }
    },
    async fetchMarketPlaceData(teamId) {
      try {
        const res = await AXIOS.get(config.marketPlace.marketPlaceData(teamId));
        if (res.data.success) {
          if (!!res.data.dataSource) {
            dispatch.integration.updateIntegratedEcommerceList(
              res.data.dataSource
            );
            return true;
          }
        } else {
          return false;
        }
      } catch (err) {
        return false;
      }
    },
    async fetchMultiEcommerceData(teamId) {
      try {
        const res = await AXIOS.get(
          config.integration.multiEcommerceData(teamId)
        );
        if (res.data.success) {
          if (!!res.data.dataSource) {
            dispatch.integration.updateIntegratedEcommerceList(
              res.data.dataSource
            );
            return true;
          }
        } else {
          return false;
        }
      } catch (err) {
        return false;
      }
    },

    async fetchEcommerceChannelPreferences(payload) {
      try {
        const res = await AXIOS.get(
          config.integration.getEcommerceChannelPreferences(
            payload.teamId,
            payload.alice_store_id
          )
        );
        if (res.data.success) {
          if (!!res.data.dataSource) {
            dispatch.integration.updateEcommerceChannelPreferencesList(
              res.data.dataSource
            );
            return true;
          }
        } else {
          return false;
        }
      } catch (err) {
        return false;
      }
    },

    async connectEcommercePlatform(payload) {
      try {
        const res = await AXIOS.post(
          config.integration.connectEcommercePlatform(payload.teamId),
          {
            alice_store_id: payload.alice_store_id,
            platform_id: payload.platform_id,
          }
        );

        if (res.data.success) {
          await dispatch.integration.fetchEcommerceChannelPreferences({
            teamId: payload.teamId,
            alice_store_id: payload.alice_store_id,
          });
          return true;
        } else {
          return false;
        }
      } catch (err) {
        return false;
      }
    },

    async disconnectEcommercePlatform(payload) {
      try {
        const res = await AXIOS.post(
          config.integration.disconnectEcommercePlatform(payload.teamId),
          {
            alice_store_id: payload.alice_store_id,
            platform_id: payload.platform_id,
          }
        );
        if (res.data.success) {
          await dispatch.integration.fetchEcommerceChannelPreferences({
            teamId: payload.teamId,
            alice_store_id: payload.alice_store_id,
          });
          return true;
        } else {
          return false;
        }
      } catch (err) {
        return false;
      }
    },
    async fetchAgentList(teamId) {
      /*
      payload= teamId : number
       */
      //note: this effect is for Reporting
      try {
        const res = await AXIOS.get(config.crm.agent(teamId));
        if (res.data.success) {
          dispatch.integration.updateStateData({
            key: 'agentList',
            value: res.data.dataSource,
          });
        } else {
          dispatch.integration.updateStateData({
            key: 'agentList',
            value: null,
          });
        }
      } catch (err) {}
    },
    async fetchAgentGroup(projectId) {
      try {
        const res = await AXIOS.get(config.crm.groups(projectId));
        if (res.status === 200 && res.data.success) {
          dispatch.integration.updateStateData({
            key: 'agentGroups',
            value: res.data.dataSource,
          });
        } else {
          dispatch.integration.updateStateData({
            key: 'agentGroups',
            value: [],
          });
        }
      } catch (err) {
        dispatch.integration.updateStateData({
          key: 'agentGroups',
          value: [],
        });
      }
    },

    async handleLiveChatMigration(payload, state) {
      try {
        const res = await AXIOS.post(
          config.integration.handleLiveChatMigration(payload.channelId)
        );
        if (res.status === 200 && res.data.success) {
          dispatch.integration.updateStateData({
            key: 'migratedWebchatId',
            value: payload.channelId,
          });

          const newList = state.integration.integratedChannelList.map((data) =>
            data.id === payload.channelId ? res.data.dataSource : data
          );

          dispatch.integration.updateIntegratedChannelList([...newList]);
          dispatch.channel.updateChannelList([...newList]);
          dispatch.dashboard.updatePlatformList([...newList]);

          // updating selected project (to get the latest value of livechat_hellobar_data) as value of
          // the number of connected webchat may change after migrating to live chat from webchat.
          let data = await dispatch.dashboard.fetchTeamDetails(
            state.dashboard.selectedProject?.id
          );
          dispatch.dashboard.updateSelectedProject(data);

          return true;
        } else {
          dispatch.integration.updateStateData({
            key: 'migratedWebchatId',
            value: null,
          });
          return false;
        }
      } catch (err) {
        dispatch.integration.updateStateData({
          key: 'migratedWebchatId',
          value: null,
        });

        return false;
      }
    },

    async verifyCustomEmailData(payload) {
      // payload: {
      //  projectId:number
      //  data: {
      //    "protocol": "string",
      //    "host": "string",
      //    "port": 0,
      //    "security": "string",
      //    "username": "user@example.com",
      //    "password": "string"
      //  }
      // }
      try {
        const res = await AXIOS.post(
          config.integration.customEmailDataVerify(payload.projectId),
          payload.data
        );
        if (res.status === 200 && res.data.success) {
          return true;
        } else {
          return false;
        }
      } catch (err) {
        console.error(err);
        return false;
      }
    },
    async fetchDynamicIntegrationList() {
      try {
        const res = await AXIOS.get(
          config.integration.getDynamicIntegrationList()
        );
        if (res.status === 200 && res.data.success) {
          return { success: true, data: res.data.dataSource };
        } else {
          return { success: false, data: [] };
        }
      } catch (err) {
        console.error(err);
        return { success: false, data: [] };
      }
    },

    async disconnectOtherChannel(payload) {
      try {
        const res = await AXIOS.post(
          config.integration.generateOtherChannelDisconnectURL(
            payload.teamId,
            payload.channelId
          )
        );
        if (res.status === 200) {
          dispatch.integration.updateSingleOtherChannelOnList(
            res.data.dataSource
          );
          toaster.success(payload.alertMessage);
          return true;
        }
      } catch (err) {
        if (err.response.status >= 500) {
          toaster.danger(
            'Something went wrong while disconnecting the channel. Try again later.'
          );
        } else {
          toaster.danger(
            err?.response?.data?.error ||
              'Something went wrong while disconnecting the channel. Try again later.'
          );
        }
        return false;
      }
    },

    async removeOtherChannel(payload) {
      try {
        const res = await AXIOS.delete(
          config.integration.generateOtherChannelDisconnectURL(
            payload.teamId,
            payload.channelId
          )
        );
        if (res.status === 200) {
          await dispatch.integration.getIntegratedOthersList(payload.teamId);
          toaster.success('Channel successfully removed');
          return true;
        }
      } catch (err) {
        if (!!err.response.status && err.response.status >= 500) {
          toaster.danger('Something went wrong while removing this channel');
        } else {
          toaster.danger(
            err?.response?.data?.error ||
              'Something went wrong while disconnecting the channel. Try again later.'
          );
        }
        return false;
      }
    },

    async editOtherChannel(payload) {
      try {
        const res = await AXIOS.patch(
          config.integration.generateOtherChannelEditURL(
            payload.teamId,
            payload.channelId
          ),
          payload.data
        );
        if (res.status === 200) {
          dispatch.integration.updateSingleOtherChannelOnList(
            res.data.dataSource
          );
          !payload.hasOwnProperty('hideToaster') &&
            toaster.success(payload.alertMessage);
          return true;
        } else return false;
      } catch (err) {
        if (!!err.response && err.response.status >= 500) {
          !payload.hasOwnProperty('hideToaster') &&
            toaster.danger('Something went wrong while updating this channel');
        } else {
          !payload.hasOwnProperty('hideToaster') &&
            toaster.danger(
              err?.response?.data?.error ||
                'Something went wrong while updating this channel'
            );
        }
        return false;
      }
    },

    async reconnectOtherChannel(payload) {
      try {
        const res = await AXIOS.post(
          config.integration.generateOtherChannelReconnectURL(
            payload.teamId,
            payload.channelId
          ),
          payload.payloadData
        );
        if (res.status === 200) {
          dispatch.integration.updateSingleOtherChannelOnList(
            res.data.dataSource
          );
          toaster.success(payload.alertMessage);
          return true;
        } else return false;
      } catch (err) {
        if (err.response.status >= 500) {
          toaster.danger(
            'Something went wrong while reconnecting this channel'
          );
        } else {
          toaster.danger(err?.response?.data?.error);
        }
        return false;
      }
    },

    async fetchTeamInfo(teamId) {
      try {
        const res = await AXIOS.get(config.dashboard.teamDetails(teamId));
        if (res.data.success) {
          return { success: true, data: res.data.dataSource };
        } else {
          return null;
        }
      } catch (err) {
        if (!err.response) {
          return {
            success: false,
            msg: 'Network error: Please check your internet connection.',
          };
        } else {
          console.log(err);
          return {
            success: false,
            msg: 'An error occurred while fetching team information.',
          };
        }
      }
    },

    async zidConnection(payload) {
      try {
        const res = await AXIOS.post(config.integration.zidIntegration(), {
          ...payload,
        });
        if (res.status === 200) {
          console.log(res);
          return { success: true };
        }
      } catch (error) {
        console.log(error);
        return {
          success: false,
          msg:
            error?.response?.data?.error ||
            'An error occurred while fetching team information.',
        };
      }
    },
    async updateWhatsappAccountInfo(channelId) {
      try {
        const res = await AXIOS.post(
          config.integration.updateWhatsAppAccountInfo(channelId)
        );
        if (res.status === 200) {
          const { dataSource } = res?.data;
          return { success: true, dataSource };
        }
      } catch (error) {
        console.log(error);
        return { success: false };
      }
    },

    async editStorName(payload) {
      try {
        const res = await AXIOS.post(
          config.integration.editStoreName(payload.teamId),
          {
            store_name: payload.storeName,
            alice_store_id: payload.storeId,
          }
        );
        if (res.status === 200 && res.data.success) {
          await dispatch.integration.updateEcommerceData(res.data.dataSource);
          return true;
        }
        return false;
      } catch (error) {
        console.error('Error updating ecommerce name: ', error);
        return false;
      }
    },
  }),
};
